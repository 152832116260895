import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Domain, DomainStatus } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDomains = (options?: UseQueryOptions<PaginatedResponse<Domain>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const filters = Object.values(DomainStatus).filter(
    status => status !== DomainStatus.TO_BE_DELETED,
  );

  const fetchDomains = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Domain>>(
      `${CONFIG.environmentConfig.apiBaseUrl}domains`,
      {
        params: {
          organization_id: organizationId,
          usage: 'server-side',
          $or: {
            status: filters,
          },
        },
      },
    );

    return response.data;
  };

  return useQuery(QUERY_KEYS.DOMAINS(organizationId), fetchDomains, options);
};
