import { Domain } from '@types';

export function domainsByDeliveryCloud(domains: Domain[], deliveryCloudDomains: Domain['id'][]) {
  return deliveryCloudDomains.reduce((acc, domainId) => {
    const domain = domains.find(domain => domain.id === domainId);

    if (!domain) {
      return acc;
    }

    return [...acc, domain];
  }, []);
}
