import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiIllustration,
  DidomiListShortener,
  DidomiSkeleton,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { tw } from '@twind/core';

import { CardSettingsInfo, DeploymentStatus, ServiceOverview } from '@components';
import { useAccessPolicyContext, useQuotasContext } from '@context';
import { useDeliveryCloudsDefaultData } from '@hooks';
import { DomainsByService } from '@modals';
import { AvailableDeliveryCloudData, DeliveryCloud, Service } from '@types';
import { DYNAMIC_PARAMS, ROUTES, getRegion } from '@utils';

interface ListDeliveryCloudsTabProps {
  deliveryClouds?: DeliveryCloud[];
}

export const ListDeliveryCloudsTab = ({ deliveryClouds }: ListDeliveryCloudsTabProps) => {
  const navigate = useNavigate();
  const { canEdit } = useAccessPolicyContext();
  const { getQuotaValueByKey } = useQuotasContext();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  const isLoading = deliveryClouds === undefined;
  const hasDeliveryClouds = deliveryClouds?.length > 0;
  const hasTooManyDeliveryClouds =
    deliveryClouds?.length >= (getQuotaValueByKey('delivery_clouds') ?? 0);

  const handleServicePreview = (deliveryCloudId: string) => (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_DETAILS.replace(
        `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
        deliveryCloudId,
      ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, serviceId),
    );

  const handleServiceEdit = (deliveryCloudId: string) => (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_EDIT.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId).replace(
        `:${DYNAMIC_PARAMS.SERVICE_ID}`,
        serviceId,
      ),
    );

  return (
    <div className="flex flex-col gap-y-s">
      <div className="flex flex-row justify-between items-center">
        <p
          className="pb-xxs gap-x-l font-semibold text-body-normal text-secondary-cobalt-blue-4"
          data-skeleton
        >
          Delivery clouds ({deliveryClouds?.length})
        </p>
        {canEdit ? (
          !isLoading && hasDeliveryClouds ? (
            <DidomiTooltip
              variant="helper"
              content={hasTooManyDeliveryClouds ? 'Only 3 Delivery Clouds can be created.' : ''}
            >
              <Link to={ROUTES.DELIVERY_CLOUDS_CREATE}>
                <DidomiButton
                  variant="main"
                  size="large"
                  disabled={hasTooManyDeliveryClouds}
                  data-skeleton
                >
                  Add delivery cloud +
                </DidomiButton>
              </Link>
            </DidomiTooltip>
          ) : (
            <DidomiSkeleton variant="unit" isLoading={isLoading} className="w-56 h-16" />
          )
        ) : null}
      </div>

      {isLoading ? (
        <>
          <DidomiSkeleton variant="unit" isLoading={isLoading} className="w-full h-64" />
          <DidomiSkeleton variant="unit" isLoading={isLoading} className="w-full h-64" />
        </>
      ) : hasDeliveryClouds ? (
        deliveryClouds.map(deliveryCloud => (
          <DeliveryCloudItem
            key={deliveryCloud.id}
            deliveryCloud={deliveryCloud}
            deliveryCloudInfo={deliveryCloudsDefaultData as AvailableDeliveryCloudData[]}
            showEdit={canEdit}
            onEdit={handleServiceEdit(deliveryCloud.id)}
            onPreview={handleServicePreview(deliveryCloud.id)}
          />
        ))
      ) : (
        <DidomiCardContainer
          style={{
            '--card-outer-padding': '0',
            '--card-inner-padding': tw.theme('spacing.xxl'),
            '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
            '--card-inner-bg': 'none',
            '--card-border': 'none',
          }}
        >
          <div className="flex flex-col items-center justify-center h-full text-primary-blue-6 text">
            <DidomiIllustration name="traces-no-result-found" className="mb-xs" />
            <div className="mb-m text-center">
              <h3 className="text-h3 font-bold">You have no Delivery clouds yet!</h3>
            </div>

            <Link to={ROUTES.DELIVERY_CLOUDS_CREATE}>
              <DidomiButton variant="main" size="medium">
                Create a Delivery cloud
              </DidomiButton>
            </Link>
          </div>
        </DidomiCardContainer>
      )}
    </div>
  );
};

const DeliveryCloudItem = ({
  deliveryCloud,
  deliveryCloudInfo,
  showEdit,
  onEdit,
  onPreview,
}: {
  deliveryCloud: DeliveryCloud;
  deliveryCloudInfo: AvailableDeliveryCloudData[];
  showEdit: boolean;
  onEdit: (serviceId: string) => void;
  onPreview: (serviceId: string) => void;
}) => {
  const [isDomainsByServiceOpen, setIsDomainsByServiceOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const latestDeployment = deliveryCloud.deployments?.[deliveryCloud.deployments?.length - 1];

  return (
    <>
      <Link
        to={ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
          `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
          deliveryCloud.id,
        )}
      >
        <DidomiCardContainer
          key={deliveryCloud.id}
          style={{
            backgroundImage: tw.theme('backgroundImage.light-blue'),
            boxShadow: tw.theme('boxShadow.sidenav-active-section'),
            '--card-outer-padding': '0',
            '--card-inner-padding': tw.theme('spacing.s'),
            '--card-outer-bg': 'transparent',
            '--card-inner-bg': 'none',
            '--card-border': 'none',
          }}
        >
          <div className="flex flex-row justify-between items-center mb-xxs">
            <h3 className="text-body-big font-semibold text-primary-blue-6">
              {deliveryCloud.name}
            </h3>
            <DeploymentStatus status={latestDeployment?.status} />
          </div>
          <div className="flex flex-row gap-x-s">
            <div className="flex flex-col w-72 gap-y-xxxs mt-7">
              <CardSettingsInfo title="Domains">
                <DidomiListShortener
                  items={
                    deliveryCloud.custom_domains.length
                      ? deliveryCloud.custom_domains
                      : [deliveryCloud.domain]
                  }
                  itemsToShow={1}
                  withTooltip
                  truncate
                />
              </CardSettingsInfo>
              <CardSettingsInfo title="Region">
                <DidomiListShortener
                  items={deliveryCloud.regions.map(region => getRegion(region))}
                  itemsToShow={1}
                  withTooltip
                  truncate
                />
              </CardSettingsInfo>
            </div>
            <div className="grow">
              <h4 className="text-body-small font-semibold text-secondary-cobalt-blue-4 mb-xxs">
                Services
              </h4>
              <div className="space-y-xxs">
                {deliveryCloud.services.map(service => (
                  <ServiceOverview
                    key={service.id}
                    service={service}
                    logoSrc={
                      deliveryCloudInfo.find(
                        deliveryCloudDefault => deliveryCloudDefault.type === service.type,
                      )?.small_logo_url
                    }
                    showEdit={showEdit}
                    withDomainsList={deliveryCloud.custom_domains.length > 0}
                    onEdit={e => {
                      e.preventDefault();
                      onEdit(service.id);
                    }}
                    onPreview={e => {
                      e.preventDefault();
                      onPreview(service.id);
                    }}
                    onShowDomainsByService={e => {
                      e.preventDefault();
                      setIsDomainsByServiceOpen(true);
                      setSelectedService(service);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </DidomiCardContainer>
      </Link>

      {deliveryCloud.custom_domains.length > 0 && (
        <DomainsByService
          isOpen={isDomainsByServiceOpen}
          serviceName={selectedService?.name}
          serviceType={selectedService?.type}
          path={selectedService?.path}
          domains={deliveryCloud.custom_domains}
          onClose={() => setIsDomainsByServiceOpen(false)}
        />
      )}
    </>
  );
};
