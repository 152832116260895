import React from 'react';
import { DidomiCardContainer, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

import { DomainStatus } from '@components';
import { Domain } from '@types';

interface DomainOverviewProps {
  domain: Domain;
  disabled?: boolean;
  onDelete: (domainId: string) => void;
}

export const DomainOverview = ({ domain, disabled = false, onDelete }: DomainOverviewProps) => {
  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': '0',
        '--card-inner-padding': '1rem',
        '--card-outer-bg': '#ffffff',
        '--card-inner-bg': 'none',
        '--card-border': 'none',
      }}
    >
      <div className="grid grid-cols-[6rem_1fr_1fr_1fr_.3fr] items-center gap-x-xs">
        <DomainStatus status={domain.status} />
        <DomainInfo title="Name" text={domain.id} disabled={disabled} />
        <DomainInfo title="Type" text={domain.type} disabled={disabled} />
        <DomainInfo title="Value" text="172.16.435.1" disabled={disabled} />
        {!disabled && (
          <DidomiIconButton
            icon="delete"
            variant="rounded"
            size="small"
            className="place-self-end"
            onClick={() => onDelete(domain.id)}
          />
        )}
      </div>
    </DidomiCardContainer>
  );
};

function DomainInfo({ title, text, disabled }: { title: string; text: string; disabled: boolean }) {
  return (
    <div>
      <p
        className={tx('text-body-extra-small', {
          'text-primary-blue-5': !disabled,
          'text-neutral-gray-0': disabled,
        })}
      >
        {title}
      </p>
      <p
        className={tx('text-body-small font-semibold', {
          'text-neutral-gray-0': disabled,
        })}
      >
        {text}
      </p>
    </div>
  );
}
