import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { Service } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useService = (deliveryCloudId: string, serviceId: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchService = async () => {
    const response = await axiosWithInterceptors.get<Service>(
      `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/services/${serviceId}`,
      {
        params: {
          organization_id: organizationId,
        },
      },
    );

    if (response.data.delivery_cloud_id !== deliveryCloudId) {
      throw new Error('Service not found');
    }

    return response.data;
  };

  return useQuery<Service, AxiosError>(QUERY_KEYS.SERVICE(serviceId), fetchService, {
    enabled: !!serviceId,
  });
};
