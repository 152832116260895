import React from 'react';

import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton } from '@didomi/ui-atoms-react';

import { SPA_FULL_NAME } from '@constants';

interface CtaProps {
  title: string | (() => React.ReactNode);
  description: string;
  actionText: string;
  onClick: VoidFunction;
}

export const Cta = ({ onClick, title, description, actionText }: CtaProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const renderProp = typeof title === 'string' ? title : title();

  return (
    <div className="rounded-lg max-w-[20.2488rem] py-m px-s text-center text-white bg-blue-rightpanel">
      <img
        src={`${ASSETS_URL}/assets/cta-image-profile.png`}
        alt=""
        className="h-[5.375rem] m-auto mb-s rounded-full border-[.3837rem] border-white"
      />
      <h4 className="font-bold text-h3 leading-h3 m-auto">{renderProp}</h4>
      <p className="text-body-normal pb-m leading-body-small">{description}</p>
      <DidomiButton variant="secondary-light" size="medium" onClick={onClick}>
        {actionText}
      </DidomiButton>
    </div>
  );
};
