import React, { PropsWithChildren } from 'react';
import { DidomiLoader } from '@didomi/ui-atoms-react';

interface LoaderProps extends PropsWithChildren<{}> {}

export const Loader = ({ children }: LoaderProps) => (
  <div className="w-full flex flex-col items-center justify-center">
    <DidomiLoader className="mb-xs" />
    {children && <div className="text-body-normal text-primary-blue-5">{children}</div>}
  </div>
);
