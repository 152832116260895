import React, { useState } from 'react';
import {
  DidomiCardContainer,
  DidomiFiltersBar,
  DidomiHintbox,
  DidomiIcon,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';

import { DomainOverview } from '@components';
import { useDeleteDomain, useEditDeliveryCloud } from '@hooks';
import { DeleteDomainModal } from '@modals';
import { Domain, DomainStatus } from '@types';

interface DomainListItemsProps {
  deliveryCloudId: string;
  baseDomain: string;
  domains: Domain[];
  tooltip: React.ReactNode;
}

export const DomainListItems = ({
  deliveryCloudId,
  baseDomain,
  domains,
  tooltip,
}: DomainListItemsProps) => {
  const [domainIdSelected, setDomainIdSelected] = useState<string | null>(null);

  const { mutateAsync: editDeliveryCloud } = useEditDeliveryCloud(deliveryCloudId);
  const { mutateAsync: deleteDomain } = useDeleteDomain();

  const handleDeleteDomain = async () => {
    try {
      await deleteDomain(domainIdSelected);
      await editDeliveryCloud({
        custom_domains: domains
          .map(domain => domain.id)
          .filter(domainId => domainId !== domainIdSelected),
      });
      setDomainIdSelected(null);
    } catch (error) {
      console.error('[DEBUG] ~ handleDeleteDomain ~ error:', error);
    }
  };

  const hasDomains = domains.length !== 0;

  return (
    <>
      <DidomiCardContainer
        style={{
          '--card-outer-padding': '0',
          '--card-inner-padding': '1rem 1.5rem',
          '--card-outer-bg': '#F9FAFA',
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div>
          <div className="flex flex-row justify-between items-center mb-xs">
            <div className="flex flex-row items-center">
              <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
                Custom domains ({domains?.length})
              </p>
              {tooltip && (
                <>
                  <div id="domains-tooltip-helper-text" className="hidden">
                    {tooltip}
                  </div>
                  <DidomiTooltip
                    class="block leading-none ml-xxs"
                    variant="helper"
                    contentElementId="domains-tooltip-helper-text"
                  >
                    <DidomiIcon name="helper-text" />
                  </DidomiTooltip>
                </>
              )}
            </div>

            <div className="flex flex-row items-center gap-x-xs">
              <DidomiFiltersBar
                className="block border-r border-neutral-gray-4 pr-xs"
                placeholderTextFilter="Search a domain by name..."
                searchValue=""
                showSearch
                collapsibleFilters={false}
                onSearchTextChange={() => {}}
              />
            </div>
          </div>

          <div className="space-y-xxxs">
            {hasDomains ? (
              <div className="space-y-xxxs">
                {domains.map(domain => (
                  <DomainOverview
                    key={domain.id}
                    domain={domain}
                    disabled={domain.status !== DomainStatus.READY}
                    onDelete={() => setDomainIdSelected(domain.id)}
                  />
                ))}
              </div>
            ) : (
              <DidomiHintbox
                iconName="warning"
                titleText={`Delivery Cloud is currently running on: ${baseDomain}`}
                contentAlign="center"
                variant="info"
              >
                Add a custom domain to your service to ensure all requests are routed through your
                own first-party domain. This will help ensure data collection is not blocked by
                tracking prevention or adblockers, and cookies have a longer lifetime
              </DidomiHintbox>
            )}
          </div>
        </div>
      </DidomiCardContainer>

      <DeleteDomainModal
        isOpen={!!domainIdSelected}
        domainName={domainIdSelected}
        onDelete={handleDeleteDomain}
        onCancel={() => setDomainIdSelected(null)}
      />
    </>
  );
};
