// src/enums/provider-id.enum.ts
var CloudProviderID = /* @__PURE__ */ function(CloudProviderID2) {
  CloudProviderID2["AWS"] = "aws";
  return CloudProviderID2;
}({});

// src/enums/cloud-provider-region.enum.ts
var AWSCloudProviderRegionId = /* @__PURE__ */ function(AWSCloudProviderRegionId2) {
  AWSCloudProviderRegionId2["AP_SOUTHEAST_1"] = "ap-southeast-1";
  AWSCloudProviderRegionId2["EU_CENTRAL_1"] = "eu-central-1";
  AWSCloudProviderRegionId2["EU_WEST_3"] = "eu-west-3";
  AWSCloudProviderRegionId2["US_EAST_1"] = "us-east-1";
  AWSCloudProviderRegionId2["US_WEST_1"] = "us-west-1";
  return AWSCloudProviderRegionId2;
}({});
var AWSCloudProviderRegionName = /* @__PURE__ */ function(AWSCloudProviderRegionName2) {
  AWSCloudProviderRegionName2["AP_SOUTHEAST_1"] = "Asia Pacific (Singapore)";
  AWSCloudProviderRegionName2["EU_CENTRAL_1"] = "Europe (Frankfurt)";
  AWSCloudProviderRegionName2["EU_WEST_3"] = "Europe (Paris)";
  AWSCloudProviderRegionName2["US_EAST_1"] = "US East (N. Virginia)";
  AWSCloudProviderRegionName2["US_WEST_1"] = "US West (N. California)";
  return AWSCloudProviderRegionName2;
}({});

// src/enums/cdn-id.enum.ts
var CdnId = /* @__PURE__ */ function(CdnId2) {
  CdnId2["CLOUDFRONT"] = "cloudfront";
  return CdnId2;
}({});

// src/providers.ts
var productionProviders = [
  {
    id: CloudProviderID.AWS,
    name: "Amazon Web Services",
    regions: [
      {
        id: AWSCloudProviderRegionId.AP_SOUTHEAST_1,
        name: AWSCloudProviderRegionName.AP_SOUTHEAST_1
      },
      {
        id: AWSCloudProviderRegionId.EU_CENTRAL_1,
        name: AWSCloudProviderRegionName.EU_CENTRAL_1
      },
      {
        id: AWSCloudProviderRegionId.US_EAST_1,
        name: AWSCloudProviderRegionName.US_EAST_1
      },
      {
        id: AWSCloudProviderRegionId.US_WEST_1,
        name: AWSCloudProviderRegionName.US_WEST_1
      },
      {
        id: AWSCloudProviderRegionId.EU_WEST_3,
        name: AWSCloudProviderRegionName.EU_WEST_3
      }
    ]
  }
];
var stagingProviders = [
  {
    id: CloudProviderID.AWS,
    name: "Amazon Web Services",
    regions: [
      {
        id: AWSCloudProviderRegionId.EU_CENTRAL_1,
        name: AWSCloudProviderRegionName.EU_CENTRAL_1
      },
      {
        id: AWSCloudProviderRegionId.US_EAST_1,
        name: AWSCloudProviderRegionName.US_EAST_1
      }
    ]
  }
];
var developmentProviders = [
  {
    id: CloudProviderID.AWS,
    name: "Amazon Web Services",
    regions: [
      {
        id: AWSCloudProviderRegionId.EU_CENTRAL_1,
        name: AWSCloudProviderRegionName.EU_CENTRAL_1
      }
    ]
  }
];
var providers = {
  production: productionProviders,
  staging: stagingProviders,
  development: developmentProviders
};
export {
  AWSCloudProviderRegionId,
  AWSCloudProviderRegionName,
  CdnId,
  CloudProviderID,
  developmentProviders,
  productionProviders,
  providers,
  stagingProviders
};
