import React from 'react';
import {
  DidomiButton,
  DidomiCopyText,
  DidomiIcon,
  DidomiModal,
  DidomiModalActions,
  DidomiModalContent,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { useAccessPolicyContext } from '@context';

interface DeliveryCloudCreatedProps {
  isOpen: boolean;
  domain: string;
  path: string;
  onAddDomains: () => void;
  onClose: () => void;
}

export const DeliveryCloudCreated = ({
  isOpen,
  domain,
  path,
  onAddDomains,
  onClose,
}: DeliveryCloudCreatedProps) => {
  const { displaySnackbar } = useSnackbar();
  const { isAdmin } = useAccessPolicyContext();

  return (
    <DidomiModal isOpen={isOpen} variant="slim" onClose={onClose} hideCloseButton>
      <div data-testid="delivery-cloud-created-modal">
        <DidomiIcon name="success" className={tx('mb-3')} />
        <DidomiModalHeader
          variant="message"
          modalSubTitle="Congratulations!"
          modalTitle="Your server-side GTM service is up!"
          modalDescription="Your Server-side GTM service will be up and running on a default domain in a few minutes."
        />
        <DidomiModalContent>
          <DidomiCopyText
            className={tx('py-xs')}
            variation="basic-outline"
            text={`${domain}${path}`}
            truncate
            onTextCopied={() => displaySnackbar('Service domain path copied!', { icon: 'check' })}
            isIconPersistent
          >
            {domain}
            {path}
          </DidomiCopyText>

          <p className="text-body-small text-primary-blue-6">
            Now, create your custom domain to ensure all requests are routed through your own
            first-party domain.
          </p>
        </DidomiModalContent>
        <DidomiModalActions>
          <DidomiButton variant="secondary" onClick={onClose}>
            Maybe later
          </DidomiButton>
          {isAdmin && (
            <DidomiButton variant="main" onClick={onAddDomains}>
              Add domains
            </DidomiButton>
          )}
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
