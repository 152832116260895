import React from 'react';
import { DidomiButton, DidomiChip } from '@didomi/ui-atoms-react';

export interface DeliveryCloudCardProps {
  name: string;
  description: string;
  isAvailable: boolean;
  logoSrc: string;
  showComingSoon?: boolean;
  actionText?: string;
}

export const DeliveryCloudCard = ({
  name,
  description,
  logoSrc,
  isAvailable,
  showComingSoon,
  actionText = 'Start',
}: DeliveryCloudCardProps) => {
  return (
    <div className="flex items-center text-primary-blue-6 bg-neutral-gray-1 rounded-lg py-m px-s">
      <img className="mx-auto h-11 w-11" src={logoSrc} alt={name} />

      <div className="grow text-body-small pl-xs">
        <h4 className="font-semibold">{name}</h4>
        <p>{description}</p>
      </div>

      {isAvailable ? (
        <DidomiButton variant="main" size="small" className="pl-s">
          {actionText}
        </DidomiButton>
      ) : (
        <div className="flex flex-row items-center flex-shrink-0 gap-x-xs pl-s">
          {showComingSoon && (
            <DidomiChip variation="basic-outline" label="Coming soon" className="w-max" />
          )}
          <DidomiButton variant="secondary" size="small" fullWidth={true}>
            {actionText}
          </DidomiButton>
        </div>
      )}
    </div>
  );
};
