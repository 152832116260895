import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiBackButtonLink,
  DidomiSecondaryHeader,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';

import { ErrorState, Sidebar, SidebarInfoSteps } from '@components';
import {
  useCreateDeliveryCloud,
  useCreateDeployment,
  useCreateService,
  useCustomSnackbar,
  useDeliveryClouds,
  useDeliveryCloudsDefaultData,
} from '@hooks';
import { ProcessingSteps } from '@modals';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

import {
  type CreateServiceSchemaType,
  DeliveryCloudStartByTypeForm,
} from './components/DeliveryCloudStartByTypeForm';

export const DeliveryCloudStartByType = () => {
  const navigate = useNavigate();
  const { serviceType, [DYNAMIC_PARAMS.DELIVERY_CLOUD_ID]: deliveryCloudId } = useParams();

  const { deliveryCloudDefaultData, isLoading: isLoadingDeliveryCloudDefaultData } =
    useDeliveryCloudsDefaultData(serviceType);
  const { data: deliveryClouds, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds();

  const { mutateAsync: createDeliveryCloud } = useCreateDeliveryCloud();
  const { mutateAsync: createService } = useCreateService();
  const { mutateAsync: publishDeliveryCloud } = useCreateDeployment();

  const { displayApiErrorSnackbar, displaySnackbar } = useCustomSnackbar();

  const [isCreatingService, setIsCreatingService] = useState<boolean>(false);
  const [urlToNavigate, setURLToNavigate] = useState<string | undefined>();
  const [serviceName, setServiceName] = useState<string>('');

  const isLoading = isLoadingDeliveryCloudDefaultData || isLoadingDeliveryClouds;

  if (!deliveryCloudDefaultData || !deliveryCloudDefaultData.is_available) {
    return <ErrorState />;
  }

  const isFirstCreation = deliveryClouds?.total === 0;
  const isAddingNewToExistingDeliveryCloud = !!deliveryCloudId;

  const handleDeliveryCloudSubmit = async (formData: CreateServiceSchemaType) => {
    setIsCreatingService(true);

    try {
      let deliveryCloudResponse;
      if (!isAddingNewToExistingDeliveryCloud) {
        const { data } = await createDeliveryCloud({
          name: `My delivery cloud #${deliveryClouds.total + 1}`,
          regions: deliveryCloudDefaultData.regions,
        });
        deliveryCloudResponse = data;
      }

      const service = await createService({
        name: formData.name,
        type: deliveryCloudDefaultData.type,
        path: deliveryCloudDefaultData.path,
        delivery_cloud_id: deliveryCloudId ?? deliveryCloudResponse.id,
        parameters: {
          sgtm_container_config: formData.containerConfig,
        },
      });

      if (!isAddingNewToExistingDeliveryCloud) {
        await publishDeliveryCloud({
          delivery_cloud_id: deliveryCloudId ?? deliveryCloudResponse.id,
        });
      }

      setServiceName(service.data.name);
      setURLToNavigate(
        ROUTES.SERVICE_DETAILS.replace(
          `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
          deliveryCloudId ?? deliveryCloudResponse.id,
        ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, service.data.id),
      );
    } catch (error) {
      displayApiErrorSnackbar(error);
    }
  };

  const handleCloseProcessingSteps = () => {
    if (urlToNavigate) {
      setIsCreatingService(false);

      displaySnackbar(`${serviceName} successfully created`, { icon: 'check' });
      navigate(urlToNavigate, { state: { isDeliveryCloudCreated: true } });
    }
  };

  const handleBackToDeliveryClouds = () => {
    if (isFirstCreation) {
      navigate(ROUTES.DELIVERY_CLOUDS_START);
    } else {
      navigate(ROUTES.DELIVERY_CLOUDS);
    }
  };

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <div className="pb-m border-b border-solid border-neutral-gray-3">
        <DidomiSecondaryHeader titleText={deliveryCloudDefaultData.name}>
          <div slot="description">{deliveryCloudDefaultData.description}</div>

          <div slot="back-button">
            <DidomiBackButtonLink
              class="cursor-pointer"
              text="Back to server-side hosting"
              onClick={handleBackToDeliveryClouds}
            />
          </div>

          <div slot="actions">
            <img
              className="mx-auto h-[52px] w-auto"
              src={deliveryCloudDefaultData.big_logo_url}
              alt={deliveryCloudDefaultData.name}
            />
          </div>
        </DidomiSecondaryHeader>
      </div>

      <div className="mt-l flex flex-row gap-x-l">
        <Sidebar className="relative">
          <SidebarInfoSteps {...deliveryCloudDefaultData.createService} withHeaderImage />
        </Sidebar>

        <DeliveryCloudStartByTypeForm
          deliveryCloud={deliveryCloudDefaultData}
          onSubmit={handleDeliveryCloudSubmit}
        />
      </div>

      <ProcessingSteps
        isOpen={isCreatingService}
        title="Delivery cloud publication progress..."
        steps={[
          'Building delivery cloud assets',
          'Building service assets',
          'Preparing deployment',
          'Ready for deployment!',
        ]}
        onClose={handleCloseProcessingSteps}
      />
    </DidomiSkeleton>
  );
};
