import React from 'react';
import { DidomiButton, DidomiCardContainer, DidomiChip } from '@didomi/ui-atoms-react';
import { tw } from '@twind/core';

export interface DeliveryCloudCardProps {
  name: string;
  description: string;
  isAvailable: boolean;
  logoSrc: string;
  showComingSoon?: boolean;
  actionText?: string;
  showAction?: boolean;
  onClick?: () => void;
}

export const DeliveryCloudCard = ({
  name,
  description,
  logoSrc,
  isAvailable,
  showComingSoon,
  actionText = 'Start',
  showAction = true,
  onClick,
}: DeliveryCloudCardProps) => {
  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': '0',
        '--card-inner-padding': `${tw.theme('space.m')} ${tw.theme('space.s')}`,
        '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
        '--card-inner-bg': 'none',
        '--card-border': 'none',
      }}
    >
      <div className="flex items-center text-primary-blue-6 rounded-lg">
        <img className="mx-auto h-11 w-11" src={logoSrc} alt={name} />

        <div className="grow text-body-small pl-xs">
          <h4 className="font-semibold">{name}</h4>
          <p>{description}</p>
        </div>

        {isAvailable ? (
          showAction && (
            <DidomiButton variant="main" size="small" className="pl-s" onClick={onClick}>
              {actionText}
            </DidomiButton>
          )
        ) : (
          <div className="flex flex-row items-center flex-shrink-0 gap-x-xs pl-s">
            {showComingSoon && (
              <DidomiChip variation="basic-outline" label="Coming soon" className="w-max" />
            )}
            {showAction && (
              <DidomiButton variant="secondary" size="small" fullWidth={true}>
                {actionText}
              </DidomiButton>
            )}
          </div>
        )}
      </div>
    </DidomiCardContainer>
  );
};
