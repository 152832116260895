import React, { useState } from 'react';
import {
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type DeleteDomainModalProps = {
  isOpen: boolean;
  domainName: string;
  onDelete?: () => void;
  onCancel?: () => void;
};

const CONFIRMATION_TEXT = 'DELETE';

export const DeleteDomainModal = ({
  isOpen,
  domainName,
  onDelete,
  onCancel,
}: DeleteDomainModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [canDelete, setCanDelete] = useState(false);

  const confirmAction = ({ detail }) => {
    setCanDelete(detail === CONFIRMATION_TEXT);
    setInputValue(detail);
  };

  const restoreValue = action => () => {
    setCanDelete(false);
    setInputValue('');

    return action();
  };

  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-xs')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalTitle="Pay attention" />
      <DidomiModalContent>
        <p className={tx('text-body-small text-primary-blue-6 mb-xs')}>
          You are about to delete <b>{domainName}</b>. Type <b>{CONFIRMATION_TEXT}</b> and click the
          button to confirm the deletion.
        </p>

        <DidomiTextInput
          name="delete-confirmation"
          hideErrorMessage
          placeholder={CONFIRMATION_TEXT}
          autoFocus
          value={inputValue}
          onValueChange={confirmAction}
        />
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={restoreValue(onCancel)}>
          Cancel
        </DidomiButton>

        <DidomiButton variant="main" disabled={!canDelete} onClick={restoreValue(onDelete)}>
          Delete
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
