import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';

import { SecondaryPageHeader } from '@components';
import { ROUTES, DYNAMIC_PARAMS } from '@utils';

interface EditPageHeaderProps {
  serviceName: string;
  deliveryCloudId: string;
  deliveryCloudName;
}

export const EditPageHeader = ({
  serviceName,
  deliveryCloudId,
  deliveryCloudName,
}: EditPageHeaderProps) => {
  const navigate = useNavigate();

  const breadCrumbs = [{ label: 'Delivery Clouds' }, { label: deliveryCloudName }];

  return (
    <SecondaryPageHeader
      pageTitle={serviceName}
      breadCrumbs={breadCrumbs}
      backButtonTooltipText="Back to delivery cloud"
      onBackClick={() =>
        navigate(
          ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
            `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
            deliveryCloudId,
          ),
        )
      }
    >
      <DidomiButton variant="secondary" size="medium" iconRight="timer">
        Logs
      </DidomiButton>
      <DidomiButton variant="secondary" size="medium" iconRight="analytics">
        Analytics
      </DidomiButton>
      <DidomiButton variant="top" size="medium" disabled>
        Publish
      </DidomiButton>
    </SecondaryPageHeader>
  );
};
