import React, { createContext, useContext } from 'react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { ACCESS_POLICIES_CONFIG } from '@constants';

export interface AccessPolicyContextType {
  canEdit?: boolean;
  canView?: boolean;
  isAdmin?: boolean;
  isLoadingAccessPolicies?: boolean;
}

export const AccessPolicyContext = createContext<AccessPolicyContextType | undefined>(undefined);

export function AccessPolicyProvider({ children }: React.PropsWithChildren<{}>) {
  const { hasAccess: hasAccessAsEditor, isLoading: isLoadingEditorAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.SERVERSIDE_MANAGE_EDITOR,
  );

  const { hasAccess: hasAccessAsViewer, isLoading: isLoadingViewerAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.SERVERSIDE_MANAGE_VIEWER,
  );

  const { hasAccess: hasAdminAccess, isLoading: isLoadingAdminAccess } = useHasAccessPolicies(
    ACCESS_POLICIES_CONFIG.ADMIN,
  );

  const isLoadingAccessPolicies =
    isLoadingEditorAccess || isLoadingViewerAccess || isLoadingAdminAccess;

  return (
    <AccessPolicyContext.Provider
      value={{
        canEdit: hasAccessAsEditor,
        canView: hasAccessAsViewer,
        isAdmin: hasAdminAccess,
        isLoadingAccessPolicies,
      }}
    >
      {children}
    </AccessPolicyContext.Provider>
  );
}

export function useAccessPolicyContext() {
  return useContext(AccessPolicyContext);
}
