import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiFiltersBar,
  DidomiHintbox,
} from '@didomi/ui-atoms-react';

import { DomainOverview, Loader } from '@components';
import { useDeleteDomain, useDomains, useEditDeliveryCloud } from '@hooks';
import { DeleteDomainModal } from '@modals';
import { DomainStatus } from '@types';
import { domainsByDeliveryCloud, DYNAMIC_PARAMS, ROUTES } from '@utils';

interface DeliveryCloudDetailsDomainsProps {
  deliveryCloudId: string;
  baseDomain: string;
  customDomains: string[];
}

export const DeliveryCloudDetailsDomains = ({
  deliveryCloudId,
  baseDomain,
  customDomains,
}: DeliveryCloudDetailsDomainsProps) => {
  const navigate = useNavigate();
  const [domainIdSelected, setDomainIdSelected] = useState<string | null>(null);

  const { data: domainsData, isLoading: isLoadingDomains } = useDomains();

  const { mutateAsync: editDeliveryCloud } = useEditDeliveryCloud(deliveryCloudId);
  const { mutateAsync: deleteDomain } = useDeleteDomain();

  // TODO: Move this filter to the useDomains hook
  const filteredDomains = useMemo(() => {
    const domains = domainsData?.data ?? [];
    return domainsByDeliveryCloud(domains, customDomains);
  }, [domainsData, customDomains]);

  if (isLoadingDomains) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const hasDomains = filteredDomains.length !== 0;

  const handleAddDomain = () =>
    navigate(ROUTES.DOMAINS.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId));

  const handleDeleteDomain = async () => {
    try {
      await deleteDomain(domainIdSelected);
      await editDeliveryCloud({
        custom_domains: customDomains.filter(domain => domain !== domainIdSelected),
      });
      setDomainIdSelected(null);
    } catch (error) {
      console.error('[DEBUG] ~ handleDeleteDomain ~ error:', error);
    }
  };

  return (
    <>
      <DidomiCardContainer
        style={{
          '--card-outer-padding': '0',
          '--card-inner-padding': '1rem 1.5rem',
          '--card-outer-bg': '#F9FAFA',
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div>
          <div className="flex flex-row justify-between items-center mb-xs">
            <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
              Custom domains ({filteredDomains?.length})
            </p>

            <div className="flex flex-row items-center gap-x-xs">
              {hasDomains && (
                <DidomiFiltersBar
                  className="block border-r border-neutral-gray-4 pr-xs"
                  placeholderTextFilter="Search a domain by name..."
                  searchValue=""
                  showSearch
                  collapsibleFilters={false}
                  onSearchTextChange={() => {}}
                />
              )}
              <DidomiButton
                variant="main"
                size="small"
                iconRight="new-create"
                onClick={handleAddDomain}
              >
                Add domain
              </DidomiButton>
            </div>
          </div>

          {hasDomains ? (
            <div className="space-y-xxs">
              {filteredDomains.map(domain => (
                <DomainOverview
                  key={domain.id}
                  domain={domain}
                  disabled={domain.status !== DomainStatus.READY}
                  onDelete={() => setDomainIdSelected(domain.id)}
                />
              ))}
            </div>
          ) : (
            <DidomiHintbox
              iconName="warning"
              titleText={`Delivery Cloud is currently running on: ${baseDomain}`}
              contentAlign="center"
              variant="info"
            >
              Add a custom domain to your service to ensure all requests are routed through your own
              first-party domain. This will help ensure data collection is not blocked by tracking
              prevention or adblockers, and cookies have a longer lifetime
            </DidomiHintbox>
          )}
        </div>
      </DidomiCardContainer>

      <DeleteDomainModal
        isOpen={!!domainIdSelected}
        domainName={domainIdSelected}
        onDelete={handleDeleteDomain}
        onCancel={() => setDomainIdSelected(null)}
      />
    </>
  );
};
