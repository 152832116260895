import {
  type AWSCloudProviderRegionId,
  type AWSCloudProviderRegionName,
  providers,
} from '@repo/cloud-providers';

export const REGIONS = providers[CONFIG.environment]
  .flatMap(provider => provider.regions)
  .reduce((acc, region) => {
    acc[region.id] = region.name;

    return acc;
  }, {} as Record<AWSCloudProviderRegionId, AWSCloudProviderRegionName>);
