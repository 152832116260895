import { useMemo } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { QUERY_KEYS, DELIVERY_CLOUDS_DEFAULT_DATA, SPA_FULL_NAME } from '@constants';
import type { AvailableDeliveryCloudData, UnavailableDeliveryCloudData } from '@types';

export const useDeliveryCloudsDefaultData = <
  T extends AvailableDeliveryCloudData | UnavailableDeliveryCloudData,
>(
  serviceType?: string,
) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME, { isStandalone: CONFIG.standalone });

  const { data: deliveryCloudsData, isLoading } = useQuery<T[], AxiosError>(
    QUERY_KEYS.DELIVERY_CLOUDS_INFO,
    () => DELIVERY_CLOUDS_DEFAULT_DATA as T[],
  );

  const deliveryCloudsDefaultData: T[] = useMemo(() => {
    if (!deliveryCloudsData) {
      return [];
    }

    return deliveryCloudsData.map(deliveryCloud => {
      if (!deliveryCloud.is_available) {
        return {
          ...deliveryCloud,
          small_logo_url: `${ASSETS_URL}/assets/${deliveryCloud.small_logo_url}`,
        };
      }

      return {
        ...deliveryCloud,
        small_logo_url: `${ASSETS_URL}/assets/${deliveryCloud.small_logo_url}`,
        big_logo_url: `${ASSETS_URL}/assets/${deliveryCloud.big_logo_url}`,
      };
    });
  }, [deliveryCloudsData, ASSETS_URL]);

  const deliveryCloudDefaultData: T | undefined = useMemo(() => {
    if (deliveryCloudsDefaultData.length && serviceType) {
      const deliveryCloudData = deliveryCloudsData.find(
        deliveryCloud => deliveryCloud.type === serviceType,
      );

      if (!deliveryCloudData || !deliveryCloudData.is_available) {
        return {
          ...deliveryCloudData,
          small_logo_url: `${ASSETS_URL}/assets/${deliveryCloudData.small_logo_url}`,
        };
      }

      return {
        ...deliveryCloudData,
        small_logo_url: `${ASSETS_URL}/assets/${deliveryCloudData.small_logo_url}`,
        big_logo_url: `${ASSETS_URL}/assets/${deliveryCloudData.big_logo_url}`,
      };
    }
  }, [deliveryCloudsDefaultData.length, serviceType, deliveryCloudsData, ASSETS_URL]);

  return {
    isLoading,
    deliveryCloudsDefaultData,
    deliveryCloudDefaultData,
  };
};
