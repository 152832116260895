import React from 'react';

import { DidomiChip, DidomiTooltip } from '@didomi/ui-atoms-react';
import { DeploymentStatus as DeploymentStatusConstant } from '@constants';

type ValueOf<T> = T[keyof T];

interface DeploymentStatusProps {
  status: ValueOf<typeof DeploymentStatusConstant> | undefined;
}

function mappingStatus(
  status: DeploymentStatusProps['status'],
): Pick<HTMLDidomiChipElement, 'label' | 'icon' | 'basicType'> {
  if (status === DeploymentStatusConstant.READY || status === DeploymentStatusConstant.RUNNING) {
    return {
      label: 'Deployment in progress',
      icon: 'reset-sm',
      basicType: 'info',
    };
  }

  if (DeploymentStatusConstant.SUCCESS === status) {
    return {
      label: 'Running',
      icon: 'check-sm',
      basicType: 'success',
    };
  }

  if (DeploymentStatusConstant.FAILURE === status) {
    return {
      label: 'Error',
      icon: 'close-sm',
      basicType: 'error',
    };
  }

  throw new Error(`Invalid status: ${status}`);
}

export const DeploymentStatus = ({ status }: DeploymentStatusProps) => {
  try {
    const { label, icon, basicType } = mappingStatus(status);

    if (status === DeploymentStatusConstant.FAILURE) {
      return (
        <DidomiTooltip
          class="block leading-none ml-xxs"
          variant="helper"
          content="An error occurred. Please publish the delivery cloud again."
        >
          <DidomiChip label={label} leftIcon={icon} basicType={basicType} data-skeleton />
        </DidomiTooltip>
      );
    }

    return <DidomiChip label={label} leftIcon={icon} basicType={basicType} data-skeleton />;
  } catch (error) {
    return null;
  }
};
