import { Outlet } from 'react-router-dom';
import React from 'react';
import { DidomiFeatureHeader, DidomiSmallPush } from '@didomi/ui-atoms-react';

export const HomeLayout = () => {
  return (
    <>
      <DidomiFeatureHeader titleText="Server-side hosting" className="w-full block mb-10">
        <div slot="description">
          Boost your advertising and website performance with server-side GTM, seamlessly deployed
          across multiple regions and domains.
        </div>

        <DidomiSmallPush
          href="https://support.didomi.io/understand-roles-management"
          target="_blank"
          slot="actions"
          text="Good to know!"
          highlighted-text="Benefits of Server-side hosting"
        ></DidomiSmallPush>
      </DidomiFeatureHeader>

      <Outlet />
    </>
  );
};
