import React from 'react';
import {
  DidomiButton,
  DidomiIcon,
  DidomiTooltip,
  DidomiCardContainer,
  DidomiLargePush,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { tw } from '@twind/core';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CardSettingsInfo, Form, ErrorState } from '@components';
import { type AvailableDeliveryCloudData } from '@types';

const createServiceSchema = z.object({
  deliveryCloudId: z.string(),
  name: z.string().trim().min(1, { message: 'Name is required' }),
  containerConfig: z.string().trim().min(1, { message: 'Container config is required' }),
});
export type CreateServiceSchemaType = z.infer<typeof createServiceSchema>;

const SERVICE_ADD_DEFAULT_VALUES = {
  deliveryCloudId: '',
  name: '',
  containerConfig: '',
};

interface DeliveryCloudStartByTypeFormProps {
  deliveryCloud: AvailableDeliveryCloudData;
  onSubmit: (formData: CreateServiceSchemaType) => void;
}

export const DeliveryCloudStartByTypeForm = ({
  deliveryCloud,
  onSubmit,
}: DeliveryCloudStartByTypeFormProps) => {
  const methods = useForm({
    defaultValues: {
      ...SERVICE_ADD_DEFAULT_VALUES,
    },
    values: {
      ...SERVICE_ADD_DEFAULT_VALUES,
      deliveryCloudId: deliveryCloud?.id,
      name: deliveryCloud?.service_name,
    },
    mode: 'onChange',
    resolver: zodResolver(createServiceSchema),
  });

  if (!deliveryCloud) {
    return <ErrorState />;
  }

  const handleFormSubmit = async (formData: z.infer<typeof createServiceSchema>) =>
    onSubmit(formData);

  return (
    <Form
      methods={methods}
      onSubmit={handleFormSubmit}
      className="grow"
      data-testid="create-delivery-cloud"
    >
      <DidomiCardContainer
        style={{
          '--card-inner-padding': 0,
          '--card-outer-padding': `2.5rem ${tw.theme('space.l')}`,
          '--card-inner-bg': 'none',
          '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
          '--card-border': 'none',
        }}
      >
        <div className="flex flex-col gap-y-xs">
          <h4 className="text-base text-primary-blue-6 font-semibold">
            {deliveryCloud.name} service
          </h4>

          <DidomiTextInput
            label="Name"
            placeholder="GTM-MWZZ08WD"
            error={methods.formState.errors?.name?.message}
            {...{
              ...methods.register('name'),
              onValueChange: methods.register('name').onChange,
            }}
          >
            <DidomiTooltip
              class="block leading-none ml-xxs"
              variant="helper"
              content="We recommend using the server GTM container ID (e.g., GTM-MWZZ08WD)"
              slot="description"
            >
              <DidomiIcon name="helper-text" />
            </DidomiTooltip>
          </DidomiTextInput>

          <DidomiTextInput
            label="Container Config"
            placeholder="Paste the container config token"
            error={methods.formState.errors?.containerConfig?.message}
            {...{
              ...methods.register('containerConfig'),
              onValueChange: methods.register('containerConfig').onChange,
            }}
          >
            <div id="container-config-helper-text">
              Paste the <i className="italic">Container Config</i> token provided{' '}
              <b>in your GTM account</b>
            </div>
            <DidomiTooltip
              class="block leading-none ml-xxs"
              variant="helper"
              contentElementId="container-config-helper-text"
              slot="description"
            >
              <DidomiIcon name="helper-text" />
            </DidomiTooltip>
          </DidomiTextInput>
          <div className="w-auto">
            <DidomiLargePush
              text="Documentation"
              highlightedText={`${deliveryCloud.name} service`}
              href="${href}"
              target="_blank"
            />
          </div>
        </div>

        <div className="flex flex-col mt-l">
          <h4 className="text-base text-primary-blue-6 font-semibold mb-xs">
            Delivery Cloud configuration
          </h4>
          <ul className="grid grid-cols-2 gap-xxs">
            <li>
              <CardSettingsInfo icon="Cloud" title="Cloud provider">
                Amazon Web Service
              </CardSettingsInfo>
            </li>
            <li>
              <CardSettingsInfo
                icon="Region"
                title="Regions"
                tooltipId="test-tooltip"
                tooltipContent={() => (
                  <>
                    You can <b>adjust the choice of regions</b> after the Delivery Cloud has been
                    created.
                  </>
                )}
              >
                America - Europe - APAC
              </CardSettingsInfo>
            </li>
            <li>
              <CardSettingsInfo icon="CDN" title="CDN">
                AWS CloudFront
              </CardSettingsInfo>
            </li>
          </ul>
        </div>

        <div className="flex justify-end mt-s">
          <DidomiButton
            type="submit"
            variant="main"
            size="medium"
            disabled={!methods.formState.isValid}
          >
            Create
          </DidomiButton>
        </div>
      </DidomiCardContainer>
    </Form>
  );
};
