import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiCardContainer,
  DidomiHintbox,
  DidomiLoader,
  DidomiTextarea,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { tx } from '@twind/core';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form, SidebarInfoSteps } from '@components';
import {
  useCustomSnackbar,
  useDeliveryCloud,
  useDeliveryCloudsDefaultData,
  useEditService,
  useService,
} from '@hooks';

import { UnsavedChanges } from '@modals';
import { AvailableDeliveryCloudData } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';
import { InputUpload } from 'components/InputUpload/InputUpload';
import { EditPageHeader } from './components/EditPageHeader';
import { ServiceEditActions } from './components/ServiceEditFormActions';

const editServiceSchema = z.object({
  name: z
    .string({
      invalid_type_error: 'Name is required',
    })
    .trim()
    .min(1, { message: 'Name is required' }),
  description: z.string().trim().nullable(),
  path: z
    .string({
      invalid_type_error: 'Name is required',
    })
    .trim()
    .min(1, { message: 'Path is required' }),
  projectId: z.string().trim().optional(),
  json: z.string().trim().optional(),
});

export const ServiceEdit = () => {
  const navigate = useNavigate();
  const { deliveryCloudId, serviceId } = useParams();
  const [isUnsavedChangesOpen, setIsUnsavedChangesOpen] = useState(false);

  const { displayApiErrorSnackbar, displaySnackbar } = useCustomSnackbar();

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);
  const { data: service, isLoading: isLoadingService } = useService(deliveryCloudId, serviceId);
  const { deliveryCloudDefaultData } = useDeliveryCloudsDefaultData<AvailableDeliveryCloudData>(
    service?.type,
  );

  const { mutateAsync: editService } = useEditService(serviceId);

  const methods = useForm({
    values: {
      name: service?.name,
      description: service?.description,
      path: service?.path,
      projectId: service?.parameters.sgtm_gcp_project_id,
      json: undefined,
    },
    mode: 'onChange',
    resolver: zodResolver(editServiceSchema),
  });

  if (isLoadingDeliveryCloud || isLoadingService) {
    return (
      <div className="grid place-content-center w-full">
        <DidomiLoader />
      </div>
    );
  }

  const serviceDetailsRoute = ROUTES.SERVICE_DETAILS.replace(
    `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
    deliveryCloudId,
  ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, service.id);

  const handleFileChange = (content?: { project_id: string }) => {
    methods.setValue('projectId', content?.project_id, { shouldValidate: true });
    methods.setValue('json', content ? JSON.stringify(content) : undefined);
  };

  const handleCancelForm = () => {
    if (Object.keys(methods.formState.dirtyFields).length) {
      return setIsUnsavedChangesOpen(true);
    }

    navigate(serviceDetailsRoute);
  };

  const handleServiceSubmit = async (data: z.infer<typeof editServiceSchema>) => {
    const { projectId, json, ...rest } = data;
    try {
      await editService({
        ...rest,
        type: service?.type,
        parameters: {
          sgtm_gcp_service_account_key_json: json,
          sgtm_gcp_project_id: projectId,
          sgtm_container_config: service?.parameters.sgtm_container_config,
        },
      });

      displaySnackbar('Service configuration has been updated', { icon: 'check' });
      navigate(serviceDetailsRoute);
    } catch (error) {
      displayApiErrorSnackbar(error);
    }
  };

  const nameController = methods.register('name');
  const descriptionController = methods.register('description');
  const projectId = methods.watch('projectId');

  return (
    <>
      <EditPageHeader
        serviceName={deliveryCloud?.name}
        deliveryCloudId={deliveryCloudId}
        deliveryCloudName={deliveryCloud?.name}
      />

      {isLoadingDeliveryCloud || isLoadingService ? (
        <div className="grid place-content-center w-full">
          <DidomiLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-row gap-x-l">
            <DidomiCardContainer
              style={{
                '--card-outer-padding': '0',
                '--card-inner-padding': '1.5rem',
                '--card-outer-bg': '#F9FAFA',
                '--card-inner-bg': 'none',
                '--card-border': 'none',
              }}
            >
              <Form methods={methods} onSubmit={handleServiceSubmit}>
                <div className="flex flex-row justify-between items-center mb-xs">
                  <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
                    Settings
                  </p>
                  <ServiceEditActions onCancel={handleCancelForm} />
                </div>

                <div className="space-y-xs">
                  <fieldset className="space-y-xxs">
                    <DidomiTextInput
                      className="block w-full"
                      label="Name"
                      placeholder="GTM-MWZZ08WD"
                      required
                      error={methods.formState.errors?.name?.message}
                      {...nameController}
                      onValueChange={nameController.onChange}
                    />
                  </fieldset>
                  <fieldset className="space-y-xxs">
                    <DidomiTextarea
                      className="block w-full"
                      label="Description"
                      description="Optional"
                      placeholder="Enter a service description here..."
                      error={methods.formState.errors?.description?.message}
                      {...descriptionController}
                      onValueChange={e => {
                        methods.setValue('description', e.detail);
                      }}
                    />
                  </fieldset>
                  <fieldset className="space-y-xxs">
                    <DidomiHintbox titleText="Container Configuration" variant="neutral">
                      This Container Config token is provided by Google when you are setting up your
                      tagging server in Google Tag Manager.
                    </DidomiHintbox>
                    <DidomiTextInput
                      className="block w-full"
                      label="GTM Container Configuration"
                      required
                      disabled
                      value={service?.parameters.sgtm_container_config}
                    />
                  </fieldset>
                  <fieldset className="space-y-xxs">
                    <DidomiHintbox titleText="Path" variant="neutral">
                      A unique path on the Delivery Cloud that routes your requests to the service
                      through the configured domains.
                    </DidomiHintbox>
                    <DidomiTextInput
                      className="block w-full"
                      label="Path"
                      error={methods.formState.errors?.path?.message}
                      {...{
                        ...methods.register('path'),
                        onValueChange: methods.register('path').onChange,
                      }}
                    />
                  </fieldset>
                  <fieldset className="space-y-xxs">
                    <DidomiHintbox titleText="Google Cloud Platform" variant="neutral">
                      Upload the JSON file generated from your Google Cloud Platform project to
                      connect BigQuery, Firestore, or both to your sGTM instance. To generate the
                      file, create a Service Account user for the relevant services in your Google
                      Cloud Platform project.
                    </DidomiHintbox>

                    <InputUpload
                      activeText="Turn off to deactivate Google services"
                      inactiveText="Turn on to activate Google services"
                      value={projectId}
                      onFileChange={handleFileChange}
                    />
                    {methods.formState.errors?.projectId?.message && (
                      <p className={tx('input-error')}>
                        {methods.formState.errors?.projectId?.message}
                      </p>
                    )}
                  </fieldset>

                  <div className="flex flex-row justify-between items-center">
                    <p className="text-body-extra-small text-primary-blue-6">
                      * Fields are required
                    </p>

                    <ServiceEditActions onCancel={handleCancelForm} />
                  </div>
                </div>
              </Form>
            </DidomiCardContainer>

            <SidebarInfoSteps {...deliveryCloudDefaultData?.connection} />
          </div>
          <UnsavedChanges
            isOpen={isUnsavedChangesOpen}
            onSave={() => handleServiceSubmit(methods.getValues())}
            onDiscardChanges={() => navigate(serviceDetailsRoute)}
          />
        </>
      )}
    </>
  );
};
