import { useSnackbar } from '@didomi/utility-react';

const errorConfig = {
  defaultMessage: 'Oops something went wrong, please try again',
  duration: 3000,
};

export const useCustomSnackbar = () => {
  const { displaySnackbar } = useSnackbar();

  const displayApiErrorSnackbar = (error: any) => {
    displaySnackbar(error?.response?.data?.message || errorConfig.defaultMessage, {
      variant: 'error',
      duration: errorConfig.duration,
    });
  };

  return { displaySnackbar, displayApiErrorSnackbar };
};
