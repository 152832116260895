import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { Service } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useEditService = (
  serviceId: string,
  options?: UseMutationOptions<AxiosResponse<Service>, AxiosError, Partial<Service>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (data: Partial<Service>) => {
      return axiosWithInterceptors.patch(
        `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/services/${serviceId}`,
        data,
        {
          params: {
            organization_id: organizationId,
          },
        },
      );
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<Service>, variables, context) => {
        queryClient.setQueryData(QUERY_KEYS.SERVICE(serviceId), response.data);

        queryClient.invalidateQueries(QUERY_KEYS.SERVICES(response.data.delivery_cloud_id));

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
};
