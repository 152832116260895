import React from 'react';

import { DeliveryCloud } from '@types';

import { DeliveryCloudDetailsDomains } from '../components/DeliveryCloudDetailsDomains';
import { DeliveryCloudDetailsServices } from '../components/DeliveryCloudDetailsServices';

interface DeliverCloudDetailsOverviewProps {
  deliveryCloud: DeliveryCloud;
}

export const DeliverCloudDetailsOverview = ({
  deliveryCloud,
}: DeliverCloudDetailsOverviewProps) => {
  return (
    <>
      <DeliveryCloudDetailsServices deliveryCloudId={deliveryCloud.id} />
      <DeliveryCloudDetailsDomains
        deliveryCloudId={deliveryCloud.id}
        baseDomain={deliveryCloud.domain}
        customDomains={deliveryCloud.custom_domains}
      />
    </>
  );
};
