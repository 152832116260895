import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Deployment, Sorting } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDeployments = (
  deliveryCloudId: string,
  sortBy: Sorting = Sorting.DESC,
  options?: UseQueryOptions<PaginatedResponse<Deployment>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchDeployments = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Deployment>>(
      `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/deployments`,
      {
        params: {
          organization_id: organizationId,
          delivery_cloud_id: deliveryCloudId,
          $sort: {
            created_at: sortBy,
          },
        },
      },
    );

    return response.data;
  };

  return useQuery<PaginatedResponse<Deployment>, AxiosError>(
    QUERY_KEYS.DEPLOYMENTS_BY_DELIVERY_CLOUD(deliveryCloudId, organizationId),
    fetchDeployments,
    options,
  );
};
