import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiTabs,
  DidomiTabHeading,
  DidomiTabHeaders,
  DidomiTabHeader,
  DidomiTabPanels,
  DidomiTabPanel,
  DidomiLoader,
} from '@didomi/ui-atoms-react';

import { useDeliveryCloud, useDeployments, useCreateDeployment } from '@hooks';
import { ProcessingSteps } from '@modals';
import { ROUTES } from '@utils';

import { PublishDeliveryCloud } from 'modals/PublishDeliveryCloud/PublishDeliveryCloud';
import { DeliveryCloudDetailsDomains } from './components/DeliveryCloudDetailsDomains';
import { DetailsPageHeader } from './components/DetailsPageHeader';
import { DeliveryCloudDetailsConfiguration } from './tabs/DeliveryCloudDetailsConfiguration';
import { DeliverCloudDetailsOverview } from './tabs/DeliveryCloudDetailsOverview';

export const DeliveryCloudDetails = () => {
  const navigate = useNavigate();
  const { deliveryCloudId } = useParams();

  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);

  const { data: deliveryCloud, isLoading } = useDeliveryCloud(deliveryCloudId);
  const { data: deployments } = useDeployments(deliveryCloudId);
  const { mutateAsync: publishDeliveryCloud } = useCreateDeployment();

  const handlePublish = async ({ description }: { description?: string }) => {
    setPublishModalOpen(false);
    setIsDeploying(true);

    await publishDeliveryCloud({
      delivery_cloud_id: deliveryCloudId,
      message: description,
    });
  };

  return (
    <>
      <DetailsPageHeader
        pageTitle={deliveryCloud?.name}
        backButtonTooltipText="Back to delivery clouds"
        deploymentStatus={deployments?.data?.[0]?.status}
        onBackClick={() => navigate(ROUTES.DELIVERY_CLOUDS)}
        onPublish={() => setPublishModalOpen(true)}
      />

      {isLoading ? (
        <div className="grid place-content-center w-full">
          <DidomiLoader />
        </div>
      ) : (
        <>
          <DidomiTabs activeTab={0}>
            <DidomiTabHeading variant="sub-level">
              <DidomiTabHeaders>
                <DidomiTabHeader name="Overview" />
                <DidomiTabHeader name="Domains" />
                <DidomiTabHeader name="Configuration" />
                <DidomiTabHeader name="Analytics" disabled />
                <DidomiTabHeader name="Logs" disabled />
              </DidomiTabHeaders>
            </DidomiTabHeading>
            <DidomiTabPanels className="py-m overflow-x-visible">
              <DidomiTabPanel>
                <div className="space-y-xs">
                  <DeliverCloudDetailsOverview deliveryCloud={deliveryCloud} />
                </div>
              </DidomiTabPanel>
              <DidomiTabPanel>
                <div className="space-y-xs">
                  <DeliveryCloudDetailsDomains
                    deliveryCloudId={deliveryCloudId}
                    baseDomain={deliveryCloud?.domain}
                    customDomains={deliveryCloud?.custom_domains}
                  />
                </div>
              </DidomiTabPanel>
              <DidomiTabPanel>
                <DeliveryCloudDetailsConfiguration deliveryCloud={deliveryCloud} />
              </DidomiTabPanel>
              <DidomiTabPanel>Analytics</DidomiTabPanel>
              <DidomiTabPanel>Logs</DidomiTabPanel>
            </DidomiTabPanels>
          </DidomiTabs>

          <PublishDeliveryCloud
            isOpen={isPublishModalOpen}
            deliveryCloud={deliveryCloud}
            onPublish={handlePublish}
            onCancel={() => setPublishModalOpen(false)}
          />

          <ProcessingSteps
            isOpen={isDeploying}
            title="Delivery cloud publication progress..."
            steps={[
              'Deployment of your service(s)',
              'Custom domain creation',
              'Deployment of your delivery cloud',
              'Ready!',
            ]}
            onClose={() => setIsDeploying(false)}
          />
        </>
      )}
    </>
  );
};
