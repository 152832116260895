import React, { useState, useEffect, useRef, type ChangeEvent } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiChip,
  DidomiIcon,
  DidomiSwitch,
} from '@didomi/ui-atoms-react';
import { tw } from '@twind/core';

interface InputUploadProps {
  activeText: string;
  inactiveText: string;
  value?: string;
  onFileChange: (content?: unknown) => void;
}

export const InputUpload = ({
  activeText,
  inactiveText,
  value,
  onFileChange,
}: InputUploadProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsOpen(!!value);
  }, [value]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    const reader = new FileReader();
    reader.onload = ev => {
      const obj = JSON.parse(ev.target.result as string);
      onFileChange(obj);
    };
    reader.readAsText(selectedFile);
  };

  const handleRemoveFile = () => onFileChange(undefined);

  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': tw.theme('spacing.s'),
        '--card-outer-bg': tw.theme('colors.white'),
        '--card-inner-bg': 'none',
        '--card-inner-padding': 0,
        '--card-radius': tw.theme('spacing.xxs'),
      }}
      className="w-full"
      data-skeleton
    >
      <div>
        <DidomiSwitch
          valueSelected={true}
          valueNotSelected={false}
          value={isOpen}
          labelVariant="no-box"
          hideErrorMessage
          onValueChange={event => setIsOpen(event.detail)}
        >
          <div className="flex items-center gap-x-xs">
            <DidomiIcon name="google" />
            {isOpen ? activeText : inactiveText}
          </div>
        </DidomiSwitch>

        {isOpen ? (
          <div className="mt-xs">
            <DidomiCardContainer
              style={{
                '--card-border': `1px dotted ${tw.theme('colors.neutral.gray.4')}`,
                '--card-inner-bg': tw.theme('colors.neutral.gray.1'),
                '--card-inner-padding': `.875rem ${tw.theme('spacing.xs')}`,
                '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
                '--card-outer-padding': tw.theme('spacing.xxxs'),
                '--card-radius': tw.theme('spacing.xxs'),
              }}
            >
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  {value ? (
                    <div className="flex items-center gap-x-xs">
                      <p className="text-body-small font-semibold text-primary-blue-6">
                        Project ID key:
                      </p>
                      <DidomiChip
                        label={value}
                        variation="basic-outline"
                        basicType="default"
                        rightIcon="close-sm"
                        onClick={handleRemoveFile}
                      />
                    </div>
                  ) : (
                    <h4 className="text-body-small text-primary-blue-6">
                      Upload a service account credentials JSON file
                    </h4>
                  )}
                </div>
                <div className="relative ml-auto">
                  <input
                    type="file"
                    accept=".json,application/json"
                    onChange={handleFileChange}
                    className="file:cursor-pointer hover:file:cursor-pointer hidden"
                    aria-label="Upload JSON file"
                    ref={fileInputRef}
                  />
                  <DidomiButton
                    type="button"
                    size="small"
                    variant="secondary"
                    iconRight="download"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Select file
                  </DidomiButton>
                </div>
              </div>
            </DidomiCardContainer>
          </div>
        ) : null}
      </div>
    </DidomiCardContainer>
  );
};
