import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Domain, DomainStatus } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useCreateDomain = (
  options?: UseMutationOptions<AxiosResponse<Domain>, AxiosError, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (domainName: string) => {
      const payload = {
        id: domainName,
        organization_id: organizationId,
        enabled: true,
        status: DomainStatus.SCHEDULED,
        type: 'custom',
        usage: 'server-side',
      };

      return axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}v1/domains`,
        payload,
      );
    },
    {
      ...options,
      onSuccess: async (response: AxiosResponse<Domain>, variables, context) => {
        const hasObservers =
          queryClient
            .getQueryCache()
            .find(QUERY_KEYS.DOMAINS(organizationId))
            ?.getObserversCount() > 0;

        if (hasObservers) {
          await queryClient.invalidateQueries(QUERY_KEYS.DOMAINS(organizationId));
        } else {
          await queryClient.refetchQueries(QUERY_KEYS.DOMAINS(organizationId));
        }

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
};
