import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiSkeleton } from '@didomi/ui-atoms-react';

import { Cta, DeliveryCloudList } from '@components';
import { useAccessPolicyContext } from '@context';
import { useDeliveryClouds, useDeliveryCloudsDefaultData } from '@hooks';
import { ServiceType } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

const mapDeliveryCloudToListItem = (service: any) => ({
  id: service.id,
  type: service.type,
  name: service.name,
  description: service.description,
  isAvailable: service.is_available,
  logoSrc: service.small_logo_url,
});

const RenderDeliveryCloudSection = ({
  children,
  clouds,
  title,
  showCount = true,
}: React.PropsWithChildren<{
  clouds: any[];
  title: string;
  showCount?: boolean;
}>) =>
  clouds.length > 0 ? (
    <div>
      <p
        className="pb-xxs gap-x-l w-full font-semibold text-body-normal text-secondary-cobalt-blue-4"
        data-skeleton
      >
        {title} {showCount && `(${clouds.length})`}
      </p>
      {children}
    </div>
  ) : null;

export const DeliveryCloudStart = () => {
  const { canEdit } = useAccessPolicyContext();
  const navigate = useNavigate();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  const deliveryClouds = data?.data;

  if (!isLoadingDeliveryClouds && deliveryClouds.length) {
    navigate(ROUTES.DELIVERY_CLOUDS);

    return null;
  }

  const availableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => deliveryCloud.is_available,
  );

  return (
    <DidomiSkeleton
      variant="layout"
      isLoading={isLoadingDeliveryClouds}
      className="flex flex-col gap-y-l"
    >
      <div className="flex flex-row gap-20">
        <div className="grow flex flex-col gap-y-10" data-testid="available-delivery-clouds">
          <RenderDeliveryCloudSection clouds={availableDeliveryClouds} title="Available now">
            <DeliveryCloudList
              deliveryClouds={[...availableDeliveryClouds].map(mapDeliveryCloudToListItem)}
              variant="block"
              actionText="Start"
              showAction={canEdit}
              onSelectServiceType={(type: ServiceType) => {
                navigate(
                  ROUTES.DELIVERY_CLOUDS_START_TYPE.replace(
                    `:${DYNAMIC_PARAMS.SERVICE_TYPE}`,
                    type,
                  ),
                );
              }}
            />
          </RenderDeliveryCloudSection>
        </div>

        <div className="flex-shrink-0">
          <Cta
            title={() => (
              <>
                Don&apos;t know
                <br />
                where to start?
              </>
            )}
            description="Reach out to your Account Manager to explore the benefits of our Server-side hosting solutions!"
            actionText="Contact us"
            onClick={() => window.open('https://www.didomi.io/request-demo', '_blank')}
          />
        </div>
      </div>
    </DidomiSkeleton>
  );
};
