import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Service } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

type ServiceData = Pick<Service, 'name' | 'type' | 'path' | 'delivery_cloud_id' | 'parameters'>;

export function useCreateService(
  options?: UseMutationOptions<
    AxiosResponse<Service>,
    AxiosError<{ message: string }>,
    ServiceData
  >,
) {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (data: ServiceData) =>
      axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}server-side/services`,
        data,
        {
          params: {
            organization_id: organizationId,
          },
        },
      ),
    {
      ...options,
      onSuccess: (response: AxiosResponse<Service>, variables, context) => {
        queryClient.setQueryData(QUERY_KEYS.CREATE_SERVICE, response.data);

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
}
