import { Link } from 'react-router-dom';
import React from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiIcon,
  DidomiIllustration,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';

import { CardSettingsInfo, DeploymentStatus } from '@components';
import { DeliveryCloud } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';
import { getRegion } from 'utils/getRegion';

interface ListDeliveryCloudsTabProps {
  deliveryClouds: DeliveryCloud[];
}

export const ListDeliveryCloudsTab = ({ deliveryClouds }: ListDeliveryCloudsTabProps) => {
  const hasDeliveryClouds = deliveryClouds.length > 0;
  const hasTooManyDeliveryClouds = deliveryClouds.length >= 3;

  return (
    <div className="flex flex-col gap-y-s">
      <div className="flex flex-row justify-between items-center">
        <p className="pb-xxs gap-x-l font-semibold text-body-normal text-secondary-cobalt-blue-4">
          Delivery clouds ({deliveryClouds.length})
        </p>
        {hasDeliveryClouds && (
          <DidomiTooltip
            variant="helper"
            content={hasTooManyDeliveryClouds ? 'Only 3 Delivery Clouds can be created.' : ''}
          >
            <Link to={ROUTES.DELIVERY_CLOUDS_CREATE}>
              <DidomiButton variant="main" size="large" disabled={hasTooManyDeliveryClouds}>
                Add delivery cloud +
              </DidomiButton>
            </Link>
          </DidomiTooltip>
        )}
      </div>

      {hasDeliveryClouds ? (
        deliveryClouds.map(deliveryCloud => (
          <DeliveryCloudItem key={deliveryCloud.id} deliveryCloud={deliveryCloud} />
        ))
      ) : (
        <DidomiCardContainer
          style={{
            '--card-outer-padding': '0',
            '--card-inner-padding': '3.8125rem',
            '--card-outer-bg': '#F9FAFA',
            '--card-inner-bg': 'none',
            '--card-border': 'none',
          }}
        >
          <div className="flex flex-col items-center justify-center h-full text-primary-blue-6 text">
            <DidomiIllustration name="traces-no-result-found" className="mb-xs" />
            <div className="mb-m text-center">
              <h3 className="text-h3 font-bold">No Delivery cloud...</h3>
              <p className="text-body-normal font-normal">
                Lorem ipsum dolor sit amet conceptetur valoris concept
              </p>
            </div>

            <DidomiButton variant="main" size="medium">
              Create a Delivery cloud
            </DidomiButton>
          </div>
        </DidomiCardContainer>
      )}
    </div>
  );
};

const DeliveryCloudItem = ({ deliveryCloud }: { deliveryCloud: DeliveryCloud }) => {
  let domainToRender = `${deliveryCloud.domain}`;
  if (deliveryCloud.custom_domains.length) {
    domainToRender = deliveryCloud.custom_domains[0];
  }

  return (
    <Link
      to={ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
        `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
        deliveryCloud.id,
      )}
    >
      <DidomiCardContainer
        key={deliveryCloud.id}
        style={{
          '--card-outer-padding': '0',
          '--card-inner-padding': '1.5rem',
          '--card-outer-bg': '#F9FAFA',
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div className="flex flex-row justify-between items-center mb-xxs">
          <h3 className="text-body-big font-semibold text-primary-blue-6">{deliveryCloud.name}</h3>
          <DeploymentStatus status={deliveryCloud.deployments?.[0]?.status} />
        </div>
        <div className="flex flex-row gap-x-s">
          <div className="flex flex-col w-72 gap-y-xxxs mt-7">
            <CardSettingsInfo title="Domains">
              <div className="flex flex-row">
                <span className="inline-block truncate">{domainToRender}</span>
                {deliveryCloud.custom_domains.length > 1 && (
                  <span className="text-secondary-cobalt-blue-3">
                    &nbsp;(+{deliveryCloud.custom_domains.length - 1})
                  </span>
                )}
              </div>
            </CardSettingsInfo>
            <CardSettingsInfo title="Region">
              <div className="flex flex-row">
                <span className="inline-block truncate">{getRegion(deliveryCloud.regions[0])}</span>
                {deliveryCloud.regions.length > 1 && (
                  <span className="text-secondary-cobalt-blue-3">
                    &nbsp;(+{deliveryCloud.regions.length - 1})
                  </span>
                )}
              </div>
            </CardSettingsInfo>
          </div>
          <div className="grow">
            <h4 className="text-body-small font-semibold text-secondary-cobalt-blue-4 mb-xxs">
              Services
            </h4>
            {!!deliveryCloud.services?.length && (
              <DidomiCardContainer
                style={{
                  '--card-outer-padding': '0',
                  '--card-inner-padding': '1rem',
                  '--card-outer-bg': '#ffffff',
                  '--card-inner-bg': 'none',
                  '--card-border': 'none',
                }}
              >
                <div className="grid grid-cols-3">
                  <div className="flex flex-row items-center gap-x-xxs">
                    <div className="flex flex-col">
                      <DidomiIcon name="add" />
                    </div>
                    <div>
                      <p className="text-body-small font-semibold text-primary-blue-6">
                        Server Side GTM
                      </p>
                      <p className="text-body-extra-small text-neutral-gray-6">/data</p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div>
                      <p className="text-body-small font-semibold text-primary-blue-6">
                        Request <b className="font-semibold">1 654 987 675</b>
                      </p>
                      <p className="text-body-extra-small text-neutral-gray-6">
                        Over the last 7 days
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div>
                      <p className="text-body-small font-semibold text-primary-blue-6">
                        Average success rate <b className="font-semibold">89%</b>
                      </p>
                      <p className="text-body-extra-small text-neutral-gray-6">
                        Over the last 4 days
                      </p>
                    </div>
                  </div>
                </div>
              </DidomiCardContainer>
            )}
          </div>
        </div>
      </DidomiCardContainer>
    </Link>
  );
};
