import React from 'react';
import {
  DidomiCopyText,
  DidomiModal,
  DidomiModalContent,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';

import { useDeliveryCloudsDefaultData } from '@hooks';
import { AvailableDeliveryCloudData } from '@types';

interface DomainsByServiceProps {
  isOpen: boolean;
  serviceName?: string;
  serviceType?: string;
  path?: string;
  domains?: string[];
  onClose: () => void;
}

export const DomainsByService = ({
  isOpen,
  serviceType,
  serviceName,
  path,
  domains,
  onClose,
}: DomainsByServiceProps) => {
  const { deliveryCloudsDefaultData }: { deliveryCloudsDefaultData: AvailableDeliveryCloudData[] } =
    useDeliveryCloudsDefaultData(serviceType);

  const { displaySnackbar } = useSnackbar();

  const handleOpenChange = e => {
    if (isOpen && !e.detail) {
      onClose();
    }
  };

  const externalServiceName = deliveryCloudsDefaultData.find(
    deliveryCloud => deliveryCloud.type === serviceType,
  )?.external_service_name;

  return (
    <DidomiModal isOpen={isOpen} variant="slim" onOpenChange={handleOpenChange} closable>
      <div data-testid="delivery-cloud-created-modal">
        <DidomiModalHeader
          variant="message"
          modalSubTitle="Service domains"
          modalTitle={`Service ${serviceName}`}
          modalDescription={`Copy the full URL and add it to your client's ${externalServiceName} container.`}
        />
        <DidomiModalContent>
          <div className={tx('flex flex-col gap-y-xs')}>
            {domains?.map(domain => (
              <DidomiCopyText
                key={`${domain}${path}`}
                variation="basic-outline"
                text={`${domain}${path}`}
                truncate
                onTextCopied={e => {
                  e.preventDefault();
                  displaySnackbar('Service domain path copied!', { icon: 'check' });
                }}
                isIconPersistent
              >
                {domain}
                {path}
              </DidomiCopyText>
            ))}
          </div>
        </DidomiModalContent>
      </div>
    </DidomiModal>
  );
};
