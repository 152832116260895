import React from 'react';
import { DidomiCardContainer, DidomiIcon, DidomiIconButton } from '@didomi/ui-atoms-react';

import { Service } from '@types';

interface ServiceOverviewProps {
  service: Service;
  onEdit: () => void;
  onPreview: () => void;
}

export const ServiceOverview = ({ service, onEdit, onPreview }: ServiceOverviewProps) => {
  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': '0',
        '--card-inner-padding': '1rem',
        '--card-outer-bg': '#ffffff',
        '--card-inner-bg': 'none',
        '--card-border': 'none',
      }}
    >
      <div className="grid grid-cols-[1fr_1fr_1fr_auto] items-center">
        <div className="flex flex-row items-center gap-x-xxs">
          <div className="flex flex-col">
            <DidomiIcon name="add" />
          </div>
          <div>
            <p className="text-body-small font-semibold text-primary-blue-6">{service.name}</p>
            <p className="text-body-extra-small text-neutral-gray-6">{service.path}</p>
          </div>
        </div>

        <div className="flex flex-row">
          <div>
            <p className="text-body-small font-semibold text-primary-blue-6">
              Request <b className="font-semibold">1 654 987 675</b>
            </p>
            <p className="text-body-extra-small text-neutral-gray-6">Over the last 7 days</p>
          </div>
        </div>

        <div className="flex flex-row">
          <div>
            <p className="text-body-small font-semibold text-primary-blue-6">
              Average success rate <b className="font-semibold">89%</b>
            </p>
            <p className="text-body-extra-small text-neutral-gray-6">Over the last 4 days</p>
          </div>
        </div>

        <div className="flex flex-row gap-x-xxs">
          <DidomiIconButton icon="edit" variant="rounded" size="small" onClick={onEdit} />
          <DidomiIconButton icon="preview" variant="rounded" size="small" onClick={onPreview} />
        </div>
      </div>
    </DidomiCardContainer>
  );
};
