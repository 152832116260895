import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiButton, DidomiCardContainer, DidomiFiltersBar } from '@didomi/ui-atoms-react';

import { Loader, ServiceOverview } from '@components';
import { useServices } from '@hooks';
import { DeliveryCloud } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

interface DeliveryCloudDetailsServicesProps {
  deliveryCloudId: DeliveryCloud['id'];
}

export const DeliveryCloudDetailsServices = ({
  deliveryCloudId,
}: DeliveryCloudDetailsServicesProps) => {
  const navigate = useNavigate();

  const { data: servicesData, isLoading: isLoadingServices } = useServices(deliveryCloudId);

  if (isLoadingServices) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const services = servicesData?.data;
  const filteredServices = services?.filter(
    service => service.delivery_cloud_id === deliveryCloudId,
  );

  const handleServicePreview = (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_DETAILS.replace(
        `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
        deliveryCloudId,
      ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, serviceId),
    );

  const handleServiceEdit = (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_EDIT.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId).replace(
        `:${DYNAMIC_PARAMS.SERVICE_ID}`,
        serviceId,
      ),
    );

  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': '0',
        '--card-inner-padding': '1rem 1.5rem',
        '--card-outer-bg': '#F9FAFA',
        '--card-inner-bg': 'none',
        '--card-border': 'none',
      }}
    >
      <div className="flex flex-row justify-between items-center mb-xs">
        <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
          Services ({filteredServices?.length})
        </p>

        <div className="flex flex-row items-center gap-x-xs">
          <DidomiFiltersBar
            className="block border-r border-neutral-gray-4 pr-xs"
            placeholderTextFilter="Search a service by name..."
            searchValue=""
            showSearch
            collapsibleFilters={false}
            onSearchTextChange={() => {}}
          />
          <DidomiButton variant="main" size="small" iconRight="new-create" onClick={() => null}>
            Add service
          </DidomiButton>
        </div>
      </div>
      <div className="space-y-xxxs">
        {filteredServices.map(service => (
          <ServiceOverview
            key={service.id}
            service={service}
            onEdit={() => handleServiceEdit(service.id)}
            onPreview={() => handleServicePreview(service.id)}
          />
        ))}
      </div>
    </DidomiCardContainer>
  );
};
