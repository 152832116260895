export const DYNAMIC_PARAMS = {
  DELIVERY_CLOUD_ID: 'deliveryCloudId',
  SERVICE_ID: 'serviceId',
  SERVICE_TYPE: 'serviceType',
};

export const ROUTES = {
  DELIVERY_CLOUDS_START: '/delivery-clouds/services/start',
  DELIVERY_CLOUDS_START_TYPE: `/delivery-clouds/services/start/:${DYNAMIC_PARAMS.SERVICE_TYPE}`,

  DELIVERY_CLOUDS_CREATE: '/delivery-clouds/create',
  DELIVERY_CLOUDS_CREATE_TYPE: `/delivery-clouds/:${DYNAMIC_PARAMS.SERVICE_TYPE}/create`,

  DELIVERY_CLOUDS: '/delivery-clouds/services',

  DELIVERY_CLOUDS_DETAILS: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,

  SERVICE_ADD: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/create`,
  SERVICE_ADD_TYPE: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_TYPE}/create`,
  SERVICE_DETAILS: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_ID}`,
  SERVICE_EDIT: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_ID}/edit`,

  DOMAINS_START: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/domains/create`,
  DOMAINS: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/domains`,
};
