import { redirect, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  DidomiButton,
  DidomiHintbox,
  DidomiLargePush,
  DidomiStepper,
  DidomiStepperStep,
  DidomiSwitch,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { parseDomain, ParseResultType } from 'parse-domain';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { SecondaryPageHeader, Form, LabeledCopy } from '@components';
import { DIDOMI_NAME_SERVERS } from '@constants';
import { useAccessPolicyContext } from '@context';
import {
  useCreateDomain,
  useDeliveryCloud,
  useEditDeliveryCloud,
  useCustomSnackbar,
  useDomains,
} from '@hooks';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

const newDomainSchema = z.object({
  domain: z
    .string()
    .trim()
    .min(1, 'Domain is required')
    .refine(domain => {
      const parsedDomain = parseDomain(domain);

      return parsedDomain.type === ParseResultType.Listed;
    }, 'Please enter a valid domain'),
  nsRecordCreated: z.literal<boolean>(true),
});

export const DomainAdd = () => {
  const { canEdit, isAdmin, isLoadingAccessPolicies } = useAccessPolicyContext();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [domainName, setDomainName] = useState<string | undefined>(undefined);

  const { [DYNAMIC_PARAMS.DELIVERY_CLOUD_ID]: cloudId } = useParams();

  const navigate = useNavigate();
  const { displayApiErrorSnackbar } = useCustomSnackbar();
  const { data: deliveryCloud } = useDeliveryCloud(cloudId);

  const {
    mutateAsync: editDeliveryCloud,
    isLoading: isAddingDomainToCloud,
    isSuccess: isDomainSubmitted,
  } = useEditDeliveryCloud(cloudId);
  const { mutateAsync: createDomain, isLoading: isCreatingDomain } = useCreateDomain();

  const breadCrumbs = [
    { label: 'Delivery Clouds' },
    { label: deliveryCloud?.name },
    { label: 'Domains' },
  ];

  const methods = useForm({
    defaultValues: { domain: '', nsRecordCreated: false },
    mode: 'onChange',
    resolver: zodResolver(newDomainSchema),
  });

  const { data: domainsData, isLoading: isDomainsLoading } = useDomains(
    { domain: domainName },
    {
      keepPreviousData: false,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  useEffect(() => {
    if (isDomainsLoading) return;

    if (activeStepIndex === 0 && domainName && !domainFieldError) {
      if (domainsData.total === 0) {
        methods.setError('domain', { message: null });
        setActiveStepIndex(1);
      } else {
        methods.setError('domain', { message: 'Domain already exists' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainsLoading, domainsData?.total]);

  if (isLoadingAccessPolicies) {
    return null;
  }

  if (!canEdit && !isAdmin) {
    redirect(ROUTES.DELIVERY_CLOUDS);

    return null;
  }

  const redirectToDeliveryCloudDetails = () => {
    navigate(
      ROUTES.DELIVERY_CLOUDS_DETAILS.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, cloudId),
    );
  };

  const submitDomain = async () => {
    try {
      await createDomain(domainFieldValue);
      await editDeliveryCloud({
        custom_domains: deliveryCloud.custom_domains.concat(domainFieldValue),
      });
      setActiveStepIndex(3);
    } catch (error) {
      displayApiErrorSnackbar(error);
    }
  };

  // Fields State
  const domainFieldState = methods.getFieldState('domain');
  const domainFieldValue = methods.watch('domain');
  const domainFieldError = methods.formState.errors.domain?.message;

  const isDomainProvided = domainFieldValue && !domainFieldState.invalid;
  const isNsRecordCreated = methods.watch('nsRecordCreated') === true;

  return (
    <>
      <SecondaryPageHeader
        pageTitle="Add a domain"
        breadCrumbs={breadCrumbs}
        backButtonTooltipText="Back to delivery cloud"
        onBackClick={redirectToDeliveryCloudDetails}
      >
        <DidomiLargePush
          text="Documentation"
          highlightedText="DNS Configuration"
          icon="export"
          href="#" // TODO: Add link
          target="_blank"
        />
      </SecondaryPageHeader>

      <DidomiHintbox className="mb-m" titleText="Follow the 4 steps below" headerIconName="edit">
        Once completed, your domain will be added to your Delivery Cloud and used to route requests.
      </DidomiHintbox>

      <Form methods={methods}>
        <DidomiStepper
          className="max-w-[600px] text-primary-blue-6"
          activeStep={activeStepIndex}
          onActiveStepChange={e => setActiveStepIndex(e.detail)}
        >
          {/* Step 1 */}
          <DidomiStepperStep
            headerText="Add a custom domain"
            disableDirectNavigation={isDomainSubmitted}
          >
            <p className="mb-xs text-body-small">
              Add a custom domain to route all requests through your own first-party domain. This
              helps prevent data collection from being blocked by tracking prevention or ad
              blockers.
            </p>

            <DidomiTextInput
              placeholder="Enter a domain name"
              error={methods.formState.errors.domain?.message}
              {...methods.register('domain')}
              onValueChange={e => {
                if (e.detail !== domainName) {
                  methods.register('domain').onChange(e);
                  setDomainName(undefined);
                }
              }}
            />

            <DidomiButton
              className="w-[200px]"
              size="small"
              disabled={!isDomainProvided}
              onClick={() => {
                if (domainFieldValue !== domainName) {
                  return setDomainName(domainFieldValue);
                }
              }}
            >
              Add custom domain
            </DidomiButton>
          </DidomiStepperStep>

          {/* Step 2 */}
          <DidomiStepperStep
            headerText="Create a NS record"
            disableDirectNavigation={!isDomainProvided || isDomainSubmitted}
          >
            <p className="mb-xs text-body-small">
              Access your DNS provider and create a new NS record with the following configuration
              to verify the custom domain.
            </p>

            <LabeledCopy className="mb-xs" copyItems={['NS']} label="Type" />

            <LabeledCopy className="mb-xs" copyItems={[domainFieldValue]} label="Host" />

            <LabeledCopy
              className="mb-xs"
              copyItems={DIDOMI_NAME_SERVERS}
              description="Create an NS record for each of Didomi's name servers in your DNS provider."
              label="Value"
            />

            <DidomiSwitch
              valueSelected={true}
              valueNotSelected={false}
              {...methods.register('nsRecordCreated')}
              onValueChange={methods.register('nsRecordCreated').onChange}
            >
              I have created the NS record(s) in my DNS provider
            </DidomiSwitch>

            <DidomiButton
              className="w-[200px]"
              size="small"
              disabled={!isNsRecordCreated}
              onClick={() => setActiveStepIndex(2)}
            >
              Next step
            </DidomiButton>
          </DidomiStepperStep>

          {/* Step 3 */}
          <DidomiStepperStep
            headerText="Submit your domain"
            disableDirectNavigation={!isNsRecordCreated || isDomainSubmitted}
          >
            <p className="mb-xs text-body-small">
              You can now submit your domain for verification. If the NS record(s) have been set up
              properly, Didomi will verify the domain and add it to your Delivery Cloud.
            </p>

            <DidomiButton
              className="w-[200px]"
              size="small"
              disabled={isCreatingDomain || isAddingDomainToCloud}
              onClick={submitDomain}
            >
              Submit my domain
            </DidomiButton>
          </DidomiStepperStep>

          {/* Step 4 */}
          <DidomiStepperStep
            headerText="Domain verification"
            disableDirectNavigation={!isDomainSubmitted}
          >
            <DidomiHintbox
              className="mb-xs"
              titleText="Your domain is now being verified."
              variant="success"
            >
              Domain verification can take up to 72 hours.
            </DidomiHintbox>

            <DidomiButton
              className="w-[200px]"
              size="small"
              type="submit"
              onClick={redirectToDeliveryCloudDetails}
            >
              Ok, got it!
            </DidomiButton>
          </DidomiStepperStep>
        </DidomiStepper>
      </Form>
    </>
  );
};
