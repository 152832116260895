import React from 'react';
import { DidomiTextInput, DidomiButton } from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { parseDomain, ParseResultType } from 'parse-domain';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form } from '@components';
import { useCustomSnackbar, useEditDeliveryCloud, useCreateDomain } from '@hooks';

interface DomainListFormProps {
  deliveryCloudId: string;
  domains: string[];
}

const addDomainSchema = z.object({
  domain: z
    .string()
    .trim()
    .min(1, 'Domain is required')
    .refine(domain => {
      const parsedDomain = parseDomain(domain);

      return parsedDomain.type === ParseResultType.Listed;
    }, 'Please enter a valid domain'),
});

export const DomainListForm = ({ deliveryCloudId, domains }: DomainListFormProps) => {
  const { displaySnackbar, displayApiErrorSnackbar } = useCustomSnackbar();

  const { mutateAsync: editDeliveryCloud, isLoading: isEditingDeliveryCloud } =
    useEditDeliveryCloud(deliveryCloudId);
  const { mutateAsync: createDomain, isLoading: isCreatingDomain } = useCreateDomain();

  const methods = useForm({
    defaultValues: {
      domain: '',
    },
    mode: 'onChange',
    resolver: zodResolver(addDomainSchema),
  });

  const handleSubmit = async (data: z.infer<typeof addDomainSchema>) => {
    try {
      await createDomain(data.domain);
      await editDeliveryCloud({
        custom_domains: domains.concat(data.domain),
      });

      displaySnackbar('Domain created successfully');
    } catch (error) {
      displayApiErrorSnackbar(error);
    }

    methods.reset();
  };

  return (
    <Form methods={methods} onSubmit={handleSubmit} className="flex flex-row gap-x-xs">
      <DidomiTextInput
        placeholder="Enter a domain name ..."
        error={methods.formState.errors.domain?.message}
        {...methods.register('domain')}
        onValueChange={methods.register('domain').onChange}
        className="grow"
      />
      <DidomiButton
        disabled={!methods.formState.isValid || isEditingDeliveryCloud || isCreatingDomain}
        type="submit"
      >
        Add
      </DidomiButton>
    </Form>
  );
};
