import React from 'react';
import { DidomiCardContainer } from '@didomi/ui-atoms-react';
import { tw, tx } from '@twind/core';

export const Sidebar = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={tx('max-w-[450px]', className)}>
      <DidomiCardContainer
        style={{
          backgroundImage: tw.theme('backgroundImage.light-blue'),
          boxShadow: tw.theme('boxShadow.login-card'),
          '--card-inner-padding': 0,
          '--card-outer-padding': 0,
          '--card-outer-bg': 'transparent',
          '--card-border': 'none',
        }}
        className="w-full"
        data-skeleton
      >
        <div className="bg-light-blue overflow-hidden rounded-lg py-10 px-l relative" data-skeleton>
          {children}
        </div>
      </DidomiCardContainer>
    </div>
  );
};
