import { css } from '@twind/core';

const inputError = () => css`
  color: #b10e00;
  margin-top: 4px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 18px;
  font-weight: 400;
`;

const rules = [['input-error', inputError]];

export const themePreset = {
  rules,
};
