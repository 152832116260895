import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiFiltersBar,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';
import { tw, tx } from '@twind/core';

import { ServiceOverview } from '@components';
import { useAccessPolicyContext } from '@context';
import { useDeliveryCloudsDefaultData, useServices } from '@hooks';
import { DomainsByService } from '@modals';
import { DeliveryCloud, Service } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

interface DeliveryCloudDetailsServicesProps {
  deliveryCloudId?: DeliveryCloud['id'];
  customDomains?: string[];
}

export const DeliveryCloudDetailsServices = ({
  deliveryCloudId,
  customDomains,
}: DeliveryCloudDetailsServicesProps) => {
  const { canEdit } = useAccessPolicyContext();
  const [isDomainsByServiceOpen, setIsDomainsByServiceOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const navigate = useNavigate();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  const { data: servicesData, isLoading: isLoadingServices } = useServices(deliveryCloudId);

  const services = servicesData?.data;
  const filteredServices =
    services?.filter(service => service.delivery_cloud_id === deliveryCloudId) ?? [];

  const handleServicePreview = (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_DETAILS.replace(
        `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
        deliveryCloudId,
      ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, serviceId),
    );

  const handleServiceEdit = (serviceId: string) =>
    navigate(
      ROUTES.SERVICE_EDIT.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId).replace(
        `:${DYNAMIC_PARAMS.SERVICE_ID}`,
        serviceId,
      ),
    );

  const handleShowDomainsByService = (service: Service) => {
    setIsDomainsByServiceOpen(true);
    setSelectedService(service);
  };

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoadingServices}>
      <DidomiCardContainer
        style={{
          boxShadow: tw.theme('boxShadow.sidenav-active-section'),
          '--card-outer-padding': '0',
          '--card-inner-padding': `${tw.theme('space.xs')} ${tw.theme('space.s')}`,
          '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div className="flex flex-row justify-between items-center w-full mb-xs" data-skeleton>
          <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
            Services ({filteredServices?.length})
          </p>

          <div className="flex flex-row items-center gap-x-xs">
            <DidomiFiltersBar
              className={tx({
                'block border-r border-neutral-gray-4 pr-xs': canEdit,
              })}
              placeholderTextFilter="Search a service by name..."
              searchValue=""
              showSearch
              collapsibleFilters={false}
              onSearchTextChange={() => {}}
            />
            {canEdit && (
              <Link
                to={ROUTES.SERVICE_ADD.replace(
                  `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
                  deliveryCloudId,
                )}
              >
                <DidomiButton variant="main" size="small" iconRight="new-create">
                  Add service
                </DidomiButton>
              </Link>
            )}
          </div>
        </div>
        <div className="space-y-xxxs">
          {isLoadingServices || customDomains === undefined ? (
            <div className="flex flex-col gap-y-xs">
              <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
              <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
              <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
            </div>
          ) : (
            filteredServices.map(service => (
              <ServiceOverview
                key={service.id}
                service={service}
                logoSrc={
                  deliveryCloudsDefaultData.find(
                    deliveryCloudDefault => deliveryCloudDefault.type === service.type,
                  )?.small_logo_url
                }
                showEdit={canEdit}
                withDomainsList={customDomains.length > 0}
                onEdit={() => handleServiceEdit(service.id)}
                onPreview={() => handleServicePreview(service.id)}
                onShowDomainsByService={() => handleShowDomainsByService(service)}
              />
            ))
          )}
        </div>
      </DidomiCardContainer>

      {customDomains?.length > 0 && (
        <DomainsByService
          isOpen={isDomainsByServiceOpen}
          serviceName={selectedService?.name}
          serviceType={selectedService?.type}
          path={selectedService?.path}
          domains={customDomains}
          onClose={() => setIsDomainsByServiceOpen(false)}
        />
      )}
    </DidomiSkeleton>
  );
};
