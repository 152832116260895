import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { type Domain, DomainStatus } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDomains = (
  filters?: {
    domain?: Domain['id'];
  },
  options?: UseQueryOptions<PaginatedResponse<Domain>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const statuses = Object.values(DomainStatus).filter(
    status => status !== DomainStatus.TO_BE_DELETED,
  );

  const fetchDomains = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Domain>>(
      `${CONFIG.environmentConfig.apiBaseUrl}domains`,
      {
        params: {
          organization_id: organizationId,
          usage: 'server-side',
          $or: {
            status: statuses,
          },
          ...(filters && {
            id: filters.domain ?? undefined,
          }),
        },
      },
    );

    return response.data;
  };

  return useQuery(
    filters
      ? QUERY_KEYS.DOMAINS_FILTERED(organizationId, filters)
      : QUERY_KEYS.DOMAINS(organizationId),
    fetchDomains,
    {
      ...options,
      enabled: !!organizationId,
    },
  );
};
