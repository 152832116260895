import React from 'react';
import { DidomiBackButtonLink, DidomiButton, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';
import { DeploymentStatus } from '@components';
import { Deployment } from '@types';

interface DetailsPageHeaderProps {
  pageTitle: string;
  backButtonTooltipText?: string;
  deploymentStatus: Deployment['status'];
  onPublish: () => void;
  onBackClick: () => void;
  children?: React.ReactNode;
}

export const DetailsPageHeader = ({
  pageTitle,
  backButtonTooltipText,
  deploymentStatus,
  onBackClick,
  onPublish,
}: DetailsPageHeaderProps) => {
  return (
    <DidomiSecondaryHeader className="block mb-m pb-m border-b border-solid border-b-neutral-gray-3">
      <div slot="header-title">
        <p className="text-primary-blue-6 text-body-extra-small">Delivery clouds</p>
        <div className="flex flex-row items-center gap-x-xs">
          <h1 className="text-h3 font-bold font-serif text-primary-blue-6">{pageTitle}</h1>
          <DeploymentStatus status={deploymentStatus} />
        </div>
      </div>

      <div slot="back-button">
        <DidomiBackButtonLink
          text={backButtonTooltipText}
          class="cursor-pointer"
          onClick={onBackClick}
        />
      </div>

      <div slot="actions">
        <DidomiButton variant="top" size="medium" onClick={onPublish}>
          Publish
        </DidomiButton>
      </div>
    </DidomiSecondaryHeader>
  );
};
