export const QUERY_KEYS = {
  DELIVERY_CLOUDS: (orgId: string, extendWith?: string[]) => [
    'delivery-clouds',
    orgId,
    ...(extendWith || []),
  ],
  DELIVERY_CLOUDS_INFO: ['delivery-clouds-info'],

  DELIVERY_CLOUD: (cloudId: string) => ['delivery-cloud', cloudId],

  SERVICES: (deliveryCloudId: string) => ['services', deliveryCloudId],
  SERVICE: (serviceId: string) => ['service', serviceId],

  CREATE_DELIVERY_CLOUD: ['create-delivery-cloud'],
  CREATE_SERVICE: ['create-service'],

  DOMAINS: (orgId: string) => ['domains', orgId],

  DEPLOYMENTS_BY_DELIVERY_CLOUD: (deliveryCloudId: string, orgId: string) => [
    'deployments',
    deliveryCloudId,
    orgId,
  ],
};
