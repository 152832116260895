import { Outlet } from 'react-router-dom';
import React from 'react';

import { ChatBubbleSpacer } from '@components';

export const BasicLayout = () => {
  return (
    <section className="p-l h-full">
      <Outlet />
      <ChatBubbleSpacer />
    </section>
  );
};
