import React from 'react';
import { DidomiCardContainer, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { type Icons } from '@didomi/ui-foundation';
import { tw, tx } from '@twind/core';

interface CardSettingsInfoProps {
  icon?: Icons;
  title: string;
  tooltipId?: string;
  tooltipContent?: string | (() => React.ReactNode);
}

export const CardSettingsInfo = ({
  icon,
  title,
  tooltipId,
  tooltipContent,
  children,
}: React.PropsWithChildren<CardSettingsInfoProps>) => {
  const tooltipIsString = typeof tooltipContent === 'string' || tooltipContent === undefined;
  const renderProp = tooltipIsString ? tooltipContent : tooltipContent();

  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': tw.theme('spacing.xs'),
        '--card-inner-padding': '0',
        '--card-outer-bg': tw.theme('colors.white'),
      }}
    >
      <div className={tx('min-w-0', { 'flex flex-row gap-x-xs': icon })}>
        {icon && (
          <div className="flex-shrink-0">
            <div className="inline-flex rounded-full bg-light-blue p-3.5">
              <DidomiIcon name={icon} className="text-primary-blue-6" />
            </div>
          </div>
        )}
        <div className="grow flex flex-col">
          <div className="flex items-center justify-between">
            <h3 className="text-body-small font-semibold text-neutral-gray-8">{title}</h3>
            {tooltipContent && (
              <div>
                <div id={tooltipId} className="hidden">
                  {renderProp}
                </div>
                <DidomiTooltip
                  class="block leading-none ml-xxs"
                  variant="helper"
                  contentElementId={tooltipId}
                  slot="description"
                >
                  <DidomiIcon name="helper-text" />
                </DidomiTooltip>
              </div>
            )}
          </div>
          <div
            className="text-body-small text-primary-blue-5 truncate inline-block w-full"
            data-skeleton
          >
            {children}
          </div>
        </div>
      </div>
    </DidomiCardContainer>
  );
};
