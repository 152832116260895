import React from 'react';
import { DidomiCopyText, DidomiIcon, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';

interface LabeledCopyProps {
  className?: string;
  description?: string;
  label?: string;
  tooltip?: string;
  copyItems: string[];
}

export const LabeledCopy = ({
  className,
  copyItems,
  description,
  label,
  tooltip,
}: LabeledCopyProps) => {
  const { displaySnackbar } = useSnackbar();

  return (
    <div className={className}>
      <div className="flex gap-xxs items-center mb-xxxs">
        {label && <div className="font-semibold text-body-small">{label}</div>}

        {tooltip && (
          <DidomiTooltip content={tooltip} variant="helper" className="leading-[0]">
            <DidomiIcon name="helper-text"></DidomiIcon>
          </DidomiTooltip>
        )}
      </div>

      {description && <div className="mb-xxs text-body-small">{description}</div>}

      <div className="flex flex-col gap-xxs">
        {copyItems.map(text => (
          <DidomiCopyText
            key={text}
            className="w-full [&_button]:text-left"
            text={text}
            truncate
            variation="basic-outline"
            is-icon-persistent="true"
            onTextCopied={() => displaySnackbar('Copied to clipboard', { icon: 'check' })}
          >
            {text}
          </DidomiCopyText>
        ))}
      </div>
    </div>
  );
};
