import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { DeliveryCloud } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

type ExtendWith = 'services' | 'deployments';

export const useDeliveryClouds = (
  extendWith?: ExtendWith[],
  options?: UseQueryOptions<PaginatedResponse<DeliveryCloud>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchDeliveryClouds = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<DeliveryCloud>>(
      `${CONFIG.environmentConfig.apiBaseUrl}server-side/delivery-clouds`,
      {
        params: {
          organization_id: organizationId,
          ...(extendWith && { $with: extendWith }),
        },
      },
    );

    return response.data;
  };

  // TODO: To improve the performance, it will be nice if extendWith is not empty,
  // update the cache key related with the new values
  return useQuery(
    QUERY_KEYS.DELIVERY_CLOUDS(organizationId, extendWith),
    fetchDeliveryClouds,
    options,
  );
};
