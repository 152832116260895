import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';

import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { useAxiosWithLogOut } from 'hooks/useAxiosWithInterceptors.hook';

interface OrganizationQuota {
  delivery_clouds: number;
  services_per_delivery_cloud: number;
  regions_per_delivery_cloud: number;
  domains_per_delivery_cloud: number;
}

interface QuotasContextType {
  organizationQuotas: OrganizationQuota | null;
  isLoading: boolean;
  error: Error | null;
  getQuotaValueByKey: (key: keyof OrganizationQuota) => number | undefined;
}

export const QuotasContext = createContext<QuotasContextType | undefined>(undefined);

export function QuotasProvider({ children }: React.PropsWithChildren<{}>) {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchOrganizationQuotas = async (): Promise<OrganizationQuota> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<OrganizationQuota>>(
      `${CONFIG.environmentConfig.apiBaseUrl}quotas`,
      {
        params: {
          organization_id: organizationId,
        },
      },
    );

    return response.data.data[0];
  };

  const {
    data: organizationQuotas,
    isLoading,
    error,
  } = useQuery<OrganizationQuota, Error>(
    QUERY_KEYS.QUOTAS(organizationId),
    fetchOrganizationQuotas,
    {
      enabled: !!organizationId,
    },
  );

  const getQuotaValueByKey = useCallback(
    (key: keyof OrganizationQuota) => (organizationQuotas ? organizationQuotas[key] : undefined),
    [organizationQuotas],
  );

  const contextValue = useMemo(
    () => ({
      organizationQuotas,
      isLoading,
      error: error || null,
      getQuotaValueByKey,
    }),
    [organizationQuotas, isLoading, error, getQuotaValueByKey],
  );

  return <QuotasContext.Provider value={contextValue}>{children}</QuotasContext.Provider>;
}

export function useQuotasContext() {
  return useContext(QuotasContext);
}
