import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiButton, DidomiHintbox, DidomiLoader } from '@didomi/ui-atoms-react';

import { DeliveryCloudList, SecondaryPageHeader } from '@components';
import { useDeliveryClouds, useDeliveryCloudsDefaultData } from '@hooks';

import { ROUTES } from '@utils';

const BREADCRUMBS = [{ label: 'Delivery Clouds' }, { label: 'Add a Delivery Cloud' }];

const mapDeliveryCloudToListItem = (service: any) => ({
  id: service.id,
  type: service.type,
  name: service.name,
  description: service.description,
  isAvailable: service.is_available,
  logoSrc: service.small_logo_url,
});

const RenderDeliveryCloudSection = ({
  children,
  clouds,
  title,
  showCount = true,
}: React.PropsWithChildren<{
  clouds: any[];
  title: string;
  showCount?: boolean;
}>) =>
  clouds.length > 0 ? (
    <div>
      <p className="pb-xxs gap-x-l font-semibold text-body-normal text-secondary-cobalt-blue-4">
        {title} {showCount && `(${clouds.length})`}
      </p>
      {children}
    </div>
  ) : null;

export const DeliveryCloudCreateByType = () => {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  if (isLoadingDeliveryClouds) {
    return (
      <div className="mb-s flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  const deliveryClouds = data?.data;

  if (!deliveryClouds.length) {
    navigate(ROUTES.DELIVERY_CLOUDS_START);

    return null;
  }

  const availableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => deliveryCloud.is_available,
  );
  const unavailableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => !deliveryCloud.is_available,
  );

  const handleBackToDeliveryClouds = () => navigate(ROUTES.DELIVERY_CLOUDS);

  return (
    <>
      <SecondaryPageHeader
        pageTitle="Select a service"
        breadCrumbs={BREADCRUMBS}
        backButtonTooltipText="Back to delivery clouds"
        onBackClick={handleBackToDeliveryClouds}
      >
        <DidomiButton variant="secondary" onClick={handleBackToDeliveryClouds}>
          Cancel
        </DidomiButton>
      </SecondaryPageHeader>

      <div className="flex flex-col gap-20">
        <RenderDeliveryCloudSection clouds={availableDeliveryClouds} title="Available">
          <DeliveryCloudList
            deliveryClouds={[...availableDeliveryClouds].map(mapDeliveryCloudToListItem)}
            variant="grid"
            actionText="Add service"
          />
        </RenderDeliveryCloudSection>

        <RenderDeliveryCloudSection clouds={unavailableDeliveryClouds} title="Under research">
          <DidomiHintbox title="Share your opinion!" header-icon-name="copy-click" className="mb-s">
            Let us know if you&apos;re interested in having us implement one of the integrations
            currently under research. This form is made with our{' '}
            <b>Preference Management Platform (PMP)</b>; if you are interested, reach out to our
            support team!
            <DidomiButton variant="main" size="small" slot="info">
              Contact support
            </DidomiButton>
          </DidomiHintbox>
          <DeliveryCloudList
            deliveryClouds={[...unavailableDeliveryClouds].map(mapDeliveryCloudToListItem)}
            variant="grid"
            actionText="I'm interested"
          />
        </RenderDeliveryCloudSection>
      </div>
    </>
  );
};
