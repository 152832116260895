import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  DidomiButton,
  DidomiHintbox,
  DidomiLargePush,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';

import { LabeledCopy, SecondaryPageHeader, SectionTitle, Sidebar } from '@components';
import { DIDOMI_NAME_SERVERS, MAX_CUSTOM_DOMAINS } from '@constants';
import { useDeliveryCloud, useDomains } from '@hooks';
import { domainsByDeliveryCloud, DYNAMIC_PARAMS, ROUTES } from '@utils';

import { DomainListForm } from './components/DomainListForm';
import { DomainListItems } from './components/DomainListItems';

export const DomainList = () => {
  const { [DYNAMIC_PARAMS.DELIVERY_CLOUD_ID]: deliveryCloudId } = useParams();
  const navigate = useNavigate();

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);
  const { data: domainsData, isLoading: isLoadingDomains } = useDomains();

  // TODO: Move this filter to the useDomains hook
  const filteredDomains = useMemo(() => {
    const domains = domainsData?.data ?? [];
    return domainsByDeliveryCloud(domains, deliveryCloud?.custom_domains ?? []);
  }, [domainsData, deliveryCloud?.custom_domains]);

  const breadCrumbs = [
    { label: 'Delivery Clouds' },
    { label: deliveryCloud?.name },
    { label: 'Domains' },
  ];

  const isLoading = isLoadingDeliveryCloud || isLoadingDomains;

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <SecondaryPageHeader
        pageTitle="Domains"
        breadCrumbs={breadCrumbs}
        backButtonTooltipText="Back to delivery cloud"
        onBackClick={() =>
          navigate(
            ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
              `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
              deliveryCloudId,
            ),
          )
        }
        data-skeleton
      >
        <DidomiLargePush
          text="Documentation"
          highlightedText="DNS Configuration"
          icon="export"
          href="#" // TODO: Add link
          target="_blank"
          data-skeleton
        />
      </SecondaryPageHeader>

      <div className="flex flex-row gap-x-l">
        <div className="grow">
          {deliveryCloud?.custom_domains.length >= MAX_CUSTOM_DOMAINS ? (
            <div>
              <DidomiHintbox
                key="max-domains"
                className="mb-m"
                variant="failure"
                titleText="You have reach the maximum amount of 10 custom domains"
                iconName="warning"
                contentAlign="start"
              >
                <p>
                  Delete one or more custom domains to create new ones, or contact our team to
                  upgrade your plan to include more domains.
                </p>
                <a
                  href="https://" // TODO: Add link
                  slot="info"
                  target="_blank"
                  rel="noreferrer"
                >
                  <DidomiButton variant="main" size="small">
                    Contact us
                  </DidomiButton>
                </a>
              </DidomiHintbox>
            </div>
          ) : (
            <div>
              <DidomiHintbox key="info-hintbox" className="mb-m" headerIconName="edit">
                <p>
                  Add up to <b>10 custom domains</b> to route all requests through your own domains.
                </p>
                <p>
                  This helps prevent data collection from being blocked by tracking prevention or ad
                  blockers.
                </p>
              </DidomiHintbox>
            </div>
          )}

          <DomainListForm
            deliveryCloudId={deliveryCloud?.id}
            domains={deliveryCloud?.custom_domains ?? []}
          />

          <DomainListItems
            deliveryCloudId={deliveryCloud?.id}
            baseDomain={deliveryCloud?.domain}
            domains={filteredDomains}
            tooltip={
              <>
                You can create <b>up to 10 custom domains</b>.
              </>
            }
          />
        </div>

        <Sidebar className="text-primary-blue-6">
          <div>
            <SectionTitle name="Hosting" />
          </div>

          <div className="mt-xxs space-y-s">
            <p>
              After registering custom domains with Didomi, configure them with your DNS provider by
              adding the following DNS record for each domain:
            </p>

            <ul className="text-body-small">
              <li>
                <p>
                  <b className="text-body-small-semibold">DNS record name</b> (“Each custom domain”)
                </p>
              </li>
              <li>
                <p>
                  <b className="text-body-small-semibold">DNS record type</b> (“NS”)
                </p>
              </li>
              <li>
                <p>
                  <b className="text-body-small-semibold">DNS record value</b>
                </p>
              </li>
            </ul>

            <LabeledCopy copyItems={DIDOMI_NAME_SERVERS} />

            <p className="text-body-small">
              <b className="text-body-small-semibold">DNS record TTL</b> (5 minutes)
            </p>

            <DidomiLargePush
              text="Documentation"
              highlightedText="DNS Configuration"
              icon="export"
              href="#" // TODO: Add link
              target="_blank"
              className="w-full"
            />
          </div>
        </Sidebar>
      </div>
    </DidomiSkeleton>
  );
};
