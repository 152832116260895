import React from 'react';
import { tx } from '@twind/core';

import { ServiceType } from '@types';

import {
  DeliveryCloudCard,
  type DeliveryCloudCardProps,
} from 'components/DeliveryCloudCard/DeliveryCloudCard';

interface DeliveryCloudListItem extends DeliveryCloudCardProps {
  id: string;
  type: ServiceType;
}

interface DeliveryCloudListProps {
  deliveryClouds: DeliveryCloudListItem[];
  variant?: 'block' | 'grid';
  showComingSoon?: boolean;
  actionText: string;
  showAction?: boolean;
  onSelectServiceType: (type: ServiceType) => void;
}

export const DeliveryCloudList = ({
  deliveryClouds,
  variant = 'block',
  showComingSoon = false,
  actionText = 'Start',
  showAction = true,
  onSelectServiceType,
}: DeliveryCloudListProps) => {
  if (!deliveryClouds.length) {
    return null;
  }

  const handleClick = (type: ServiceType, isAvailable: boolean) => () => {
    if (isAvailable) {
      onSelectServiceType(type);

      return;
    }

    return null;
  };

  return (
    <ul
      className={tx('grid', {
        'w-full grid-cols-1 gap-y-xxs': variant === 'block',
        'grid-cols-2 gap-xs': variant === 'grid',
      })}
      data-skeleton
    >
      {deliveryClouds.map(deliveryCloud => (
        <li key={deliveryCloud.id}>
          <DeliveryCloudCard
            key={deliveryCloud.id}
            name={deliveryCloud.name}
            description={deliveryCloud.description}
            isAvailable={deliveryCloud.isAvailable}
            logoSrc={deliveryCloud.logoSrc}
            showComingSoon={showComingSoon}
            actionText={actionText}
            showAction={showAction}
            onClick={handleClick(deliveryCloud.type, deliveryCloud.isAvailable)}
          />
        </li>
      ))}
    </ul>
  );
};
