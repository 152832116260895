import React, { useState } from 'react';

import {
  DidomiButton,
  DidomiCardContainer,
  DidomiIcon,
  DidomiSelect,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiTextarea,
  DidomiTextInput,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AWSCloudProviderRegionId } from '@repo/cloud-providers';
import { tw } from '@twind/core';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { CardSettingsInfo, Form } from '@components';
import { CLOUD_PROVIDERS, CDN_PROVIDERS, REGIONS, EMPTY_FIELD } from '@constants';
import { useAccessPolicyContext, useQuotasContext } from '@context';
import { useCustomSnackbar, useEditDeliveryCloud } from '@hooks';
import { DeliveryCloud } from '@types';

const editDeliveryCloudSchema = z.object({
  name: z
    .string({
      invalid_type_error: 'Name is required',
    })
    .trim()
    .min(1, { message: 'Name is required' }),
  description: z
    .string()
    .trim()
    .transform(val => (val === '' ? null : val))
    .nullable(),
  regions: z.array(z.nativeEnum(AWSCloudProviderRegionId)).min(1, {
    message: 'Regions are required',
  }),
});

interface DeliverCloudDetailsOverviewProps {
  deliveryCloud: DeliveryCloud;
}

export const DeliveryCloudDetailsConfiguration = ({
  deliveryCloud,
}: DeliverCloudDetailsOverviewProps) => {
  const { canEdit } = useAccessPolicyContext();
  const [isEditing, setIsEditing] = useState(false);

  const { getQuotaValueByKey } = useQuotasContext();

  const { displayApiErrorSnackbar, displaySnackbar } = useCustomSnackbar();

  const { mutateAsync: updateDeliveryCloud } = useEditDeliveryCloud(deliveryCloud?.id);

  const MAX_REGIONS_PER_SERVICE = getQuotaValueByKey('regions_per_delivery_cloud');

  const methods = useForm({
    values: {
      name: deliveryCloud?.name,
      description: deliveryCloud?.description,
      regions: deliveryCloud?.regions,
    },
    mode: 'onChange',
    resolver: zodResolver(editDeliveryCloudSchema),
  });

  const handleCancel = () => {
    setIsEditing(false);
    methods.reset();
  };

  const handleServiceSubmit = async (data: z.infer<typeof editDeliveryCloudSchema>) => {
    try {
      await updateDeliveryCloud(data);

      displaySnackbar('Delivery Cloud configuration has been updated', { icon: 'check' });
      setIsEditing(false);
    } catch (error) {
      displayApiErrorSnackbar('Oops an error occurred, please try again');
    }
  };

  const nameController = methods.register('name');
  const descriptionController = methods.register('description');
  const regionsController = methods.register('regions');
  const regionsSelected = methods.watch('regions');

  return (
    <div className="w-full xl:w-1/2">
      <DidomiCardContainer
        style={{
          '--card-outer-padding': '0',
          '--card-inner-padding': tw.theme('spacing.s'),
          '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <Form methods={methods} onSubmit={handleServiceSubmit}>
          <div className="flex flex-row justify-between items-center mb-xs">
            <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
              Delivery cloud
            </p>

            {isEditing ? (
              <div className="flex flex-row items-center gap-x-xxs">
                <DidomiButton variant="secondary" size="small" onClick={handleCancel}>
                  Cancel
                </DidomiButton>
                <DidomiButton type="submit" variant="main" size="small">
                  Save
                </DidomiButton>
              </div>
            ) : (
              canEdit && (
                <DidomiButton variant="secondary" size="small" onClick={() => setIsEditing(true)}>
                  Edit
                </DidomiButton>
              )
            )}
          </div>

          <div className="space-y-xxs">
            {isEditing ? (
              <>
                <fieldset>
                  <DidomiTextInput
                    className="block w-full"
                    label="Name"
                    placeholder="GTM-MWZZ08WD"
                    error={methods.formState.errors?.name?.message}
                    {...nameController}
                    onValueChange={nameController.onChange}
                  />
                </fieldset>

                <fieldset>
                  <DidomiTextInput
                    className="block w-full"
                    label="Cloud provider"
                    value={CLOUD_PROVIDERS[deliveryCloud?.cloud_provider]}
                    disabled
                  >
                    <div id="container-description-helper">
                      Cloud provider <b>can not be changed</b>
                    </div>
                    <DidomiTooltip
                      class="block leading-none ml-xxs"
                      variant="helper"
                      contentElementId="container-description-helper"
                      slot="description"
                    >
                      <DidomiIcon name="helper-text"></DidomiIcon>
                    </DidomiTooltip>
                  </DidomiTextInput>
                </fieldset>

                <fieldset>
                  <DidomiTextarea
                    className="block w-full"
                    label="Description"
                    description="Optional"
                    placeholder="Enter a delivery cloud description here..."
                    error={methods.formState.errors?.description?.message}
                    {...descriptionController}
                    onValueChange={e => methods.setValue('description', e.detail)}
                  />
                </fieldset>

                <fieldset>
                  <DidomiSelect
                    name="regions"
                    placeholder="Select a region ..."
                    label="Regions"
                    maxVisibleItems={4}
                    multi
                    {...regionsController}
                    onValueChange={e => {
                      regionsController.onChange(e);
                    }}
                    error={methods.formState.errors?.regions?.message}
                  >
                    <div
                      slot="description"
                      className="text-field-helper-text text-primary-blue-6 ml-xxs"
                    >
                      You can select up to {MAX_REGIONS_PER_SERVICE} regions
                    </div>
                    <DidomiSelectOptions>
                      {Object.entries(REGIONS)?.map(([key, value]) => (
                        <DidomiSelectOption
                          key={key}
                          value={key as AWSCloudProviderRegionId}
                          label={value}
                          disabled={
                            regionsSelected.length >= MAX_REGIONS_PER_SERVICE &&
                            !regionsSelected.includes(key as AWSCloudProviderRegionId)
                          }
                          selected={regionsSelected.includes(key as AWSCloudProviderRegionId)}
                        />
                      ))}
                    </DidomiSelectOptions>
                  </DidomiSelect>
                </fieldset>

                <fieldset>
                  <DidomiTextInput
                    className="block w-full"
                    label="CDN"
                    value={CDN_PROVIDERS[deliveryCloud?.cdn]}
                    disabled
                  >
                    <div id="container-cloud-helper">
                      CDN <b>can not be changed</b>
                    </div>
                    <DidomiTooltip
                      class="block leading-none ml-xxs"
                      variant="helper"
                      contentElementId="container-cloud-helper"
                      slot="description"
                    >
                      <DidomiIcon name="helper-text"></DidomiIcon>
                    </DidomiTooltip>
                  </DidomiTextInput>
                </fieldset>
              </>
            ) : (
              <>
                <CardSettingsInfo title="Name">
                  {deliveryCloud?.name ?? EMPTY_FIELD}
                </CardSettingsInfo>
                <CardSettingsInfo title="Cloud provider">
                  {deliveryCloud?.cloud_provider
                    ? CLOUD_PROVIDERS[deliveryCloud?.cloud_provider]
                    : EMPTY_FIELD}
                </CardSettingsInfo>
                <CardSettingsInfo title="Regions">
                  {deliveryCloud?.regions.map(region => REGIONS[region]).join(', ') ?? EMPTY_FIELD}
                </CardSettingsInfo>
                <CardSettingsInfo title="CDN">
                  {deliveryCloud?.cdn ? CDN_PROVIDERS[deliveryCloud?.cdn] : EMPTY_FIELD}
                </CardSettingsInfo>
              </>
            )}
          </div>
        </Form>
      </DidomiCardContainer>
    </div>
  );
};
