import { NavLink } from 'react-router-dom';
import React from 'react';
import { tx } from '@twind/core';

import {
  DeliveryCloudCard,
  type DeliveryCloudCardProps,
} from 'components/DeliveryCloudCard/DeliveryCloudCard';

interface DeliveryCloudListItem extends DeliveryCloudCardProps {
  id: string;
  type: string;
}

interface DeliveryCloudListProps {
  deliveryClouds: DeliveryCloudListItem[];
  variant?: 'block' | 'grid';
  showComingSoon?: boolean;
  actionText: string;
}

export const DeliveryCloudList = ({
  deliveryClouds,
  variant = 'block',
  showComingSoon = false,
  actionText = 'Start',
}: DeliveryCloudListProps) => {
  if (!deliveryClouds.length) {
    return null;
  }

  return (
    <ul
      className={tx('grid', {
        'grid-cols-1 gap-y-xxs': variant === 'block',
        'grid-cols-2 gap-xs': variant === 'grid',
      })}
    >
      {deliveryClouds.map(deliveryCloud => (
        <li key={deliveryCloud.id}>
          <NavLink to={deliveryCloud.type}>
            <DeliveryCloudCard
              key={deliveryCloud.id}
              name={deliveryCloud.name}
              description={deliveryCloud.description}
              isAvailable={deliveryCloud.isAvailable}
              logoSrc={deliveryCloud.logoSrc}
              showComingSoon={showComingSoon}
              actionText={actionText}
            />
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
