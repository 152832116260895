export enum DomainStatus {
  SCHEDULED = 'scheduled',
  TO_BE_DELETED = 'to_be_deleted',
  DNS_NOT_READY = 'dns_not_ready',
  CERTIFICATE_CREATION_IN_PROGRESS = 'certificate_creation_in_progress',
  CERTIFICATE_VALIDATION_FAILED = 'certificate_validation_failed',
  CERTIFICATE_VALIDATION_IN_PROGRESS = 'certificate_validation_in_progress',
  DISTRIBUTION_CREATION_IN_PROGRESS = 'distribution_creation_in_progress',
  READY = 'ready',
}
