import { Link } from 'react-router-dom';
import React from 'react';
import { BreadCrumb } from '@types';

interface BreadcrumbProps {
  items: BreadCrumb[];
}

/**
 * BreadCrumbs component renders a breadcrumb navigation based on the provided items.
 *
 * The component calculates a default relative path for each breadcrumb item if the `path` is not provided.
 * The relative path is determined by the item's index in the array:
 * - The last item would have './../'
 * - The second to last item would have './../../'
 * - And so on...
 */
export const BreadCrumbs = ({ items }: BreadcrumbProps) => {
  const getRelativePathPerItemIndex = (index: number) => {
    const nestingLevel = items.length - index;
    return './' + '../'.repeat(nestingLevel);
  };

  return (
    <div className="w-full text-primary-blue-6 text-body-extra-small" data-skeleton>
      {items.map((breadcrumb, index) => (
        <span key={breadcrumb.path}>
          <Link to={breadcrumb.path || getRelativePathPerItemIndex(index)}>{breadcrumb.label}</Link>
          {index < items.length - 1 && <span> &gt; </span>}
        </span>
      ))}
    </div>
  );
};
