import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { DidomiButton, DidomiSkeleton } from '@didomi/ui-atoms-react';

import { DeliveryCloudList, SecondaryPageHeader } from '@components';
import { useAccessPolicyContext } from '@context';
import { useDeliveryCloud, useDeliveryClouds, useDeliveryCloudsDefaultData } from '@hooks';
import { ServiceType } from '@types';

import { DYNAMIC_PARAMS, ROUTES } from '@utils';

const mapDeliveryCloudToListItem = (service: any) => ({
  id: service.id,
  type: service.type,
  name: service.name,
  description: service.description,
  isAvailable: service.is_available,
  logoSrc: service.small_logo_url,
});

const RenderDeliveryCloudSection = ({
  children,
  clouds,
  title,
  showCount = true,
}: React.PropsWithChildren<{
  clouds: any[];
  title: string;
  showCount?: boolean;
}>) =>
  clouds.length > 0 ? (
    <div>
      <p
        className="pb-xxs gap-x-l w-full font-semibold text-body-normal text-secondary-cobalt-blue-4"
        data-skeleton
      >
        {title} {showCount && `(${clouds.length})`}
      </p>
      {children}
    </div>
  ) : null;

export const DeliveryCloudCreateByType = ({ backRoute }: { backRoute: string }) => {
  const navigate = useNavigate();
  const { [DYNAMIC_PARAMS.DELIVERY_CLOUD_ID]: deliveryCloudId } = useParams();

  const { canEdit } = useAccessPolicyContext();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);

  const isLoading = isLoadingDeliveryClouds || isLoadingDeliveryCloud;

  const deliveryClouds = data?.data;

  if (!isLoading && !deliveryClouds.length) {
    navigate(ROUTES.DELIVERY_CLOUDS_START);

    return null;
  }

  const breadcrumbs = [
    { label: 'Delivery Clouds' },
    { label: deliveryCloudId ? deliveryCloud?.name : 'Add a Delivery Cloud' },
    ...(deliveryCloudId ? [{ label: 'Services' }] : []),
  ];

  const availableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => deliveryCloud.is_available,
  );

  const handleBackToDeliveryClouds = () =>
    navigate(backRoute.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId));

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <SecondaryPageHeader
        pageTitle={deliveryCloudId ? 'Add a service' : 'Select a service'}
        breadCrumbs={breadcrumbs}
        backButtonTooltipText="Back to delivery clouds"
        onBackClick={handleBackToDeliveryClouds}
      >
        <DidomiButton variant="secondary" onClick={handleBackToDeliveryClouds}>
          Cancel
        </DidomiButton>
      </SecondaryPageHeader>
      <div className="flex flex-col gap-20">
        <RenderDeliveryCloudSection clouds={availableDeliveryClouds} title="Available">
          <DeliveryCloudList
            deliveryClouds={[...availableDeliveryClouds].map(mapDeliveryCloudToListItem)}
            variant="grid"
            actionText="Start"
            showAction={canEdit}
            onSelectServiceType={(type: ServiceType) => {
              if (deliveryCloudId) {
                return navigate(
                  ROUTES.SERVICE_ADD_TYPE.replace(
                    `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
                    deliveryCloudId,
                  ).replace(`:${DYNAMIC_PARAMS.SERVICE_TYPE}`, type),
                );
              }

              navigate(
                ROUTES.DELIVERY_CLOUDS_CREATE_TYPE.replace(`:${DYNAMIC_PARAMS.SERVICE_TYPE}`, type),
              );
            }}
          />
        </RenderDeliveryCloudSection>
      </div>
    </DidomiSkeleton>
  );
};
