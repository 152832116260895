import {
  AccessTypes,
  adminAccessPolicy,
  AvailableModules,
  modules,
} from '@didomi/authorization-metadata';

const SERVER_SERVICES_POLICIES_METADATA = modules[AvailableModules.ServerSide];

export const ACCESS_POLICIES_CONFIG = {
  SERVERSIDE_MANAGE_VIEWER:
    SERVER_SERVICES_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Viewer],
  SERVERSIDE_MANAGE_EDITOR:
    SERVER_SERVICES_POLICIES_METADATA.accessTypeAllowedPolicies[AccessTypes.Editor],
  ADMIN: [adminAccessPolicy.id],
};
