import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiTabs,
  DidomiTabHeading,
  DidomiTabHeaders,
  DidomiTabHeader,
  DidomiTabPanels,
  DidomiTabPanel,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';

import { useDeliveryCloud, useDeployments, useCreateDeployment, useTabUrl } from '@hooks';
import { DeploymentConfirmation, ProcessingSteps } from '@modals';
import { ROUTES } from '@utils';

import { PublishDeliveryCloud } from 'modals/PublishDeliveryCloud/PublishDeliveryCloud';
import { DeliveryCloudDetailsDomains } from './components/DeliveryCloudDetailsDomains';
import { DeliveryCloudDetailsServices } from './components/DeliveryCloudDetailsServices';
import { DetailsPageHeader } from './components/DetailsPageHeader';
import { DeliveryCloudDetailsAnalytics } from './tabs/DeliveryCloudDetailsAnalytics';
import { DeliveryCloudDetailsConfiguration } from './tabs/DeliveryCloudDetailsConfiguration';

const TAB_STEPS = [
  { path: 'overview', label: 'Overview' },
  { path: 'domains', label: 'Domains' },
  { path: 'configuration', label: 'Configuration' },
  { path: 'analytics', label: 'Analytics' },
  { path: 'logs', label: 'Logs', isDisabled: true },
];

export const DeliveryCloudDetails = () => {
  const navigate = useNavigate();
  const { deliveryCloudId } = useParams();

  const { activeTab, setActiveTab } = useTabUrl({ steps: TAB_STEPS });

  const [isPublishModalOpen, setPublishModalOpen] = useState(false);
  const [isDeploying, setIsDeploying] = useState(false);
  const [isDeploymentConfirmationOpen, setDeploymentConfirmationOpen] = useState(false);

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);
  const { data: deployments, isLoading: isLoadingDeployments } = useDeployments(deliveryCloudId);
  const { mutateAsync: publishDeliveryCloud } = useCreateDeployment();

  const isLoading = isLoadingDeliveryCloud || isLoadingDeployments;

  const handlePublish = async ({ description }: { description?: string }) => {
    setPublishModalOpen(false);
    setIsDeploying(true);

    await publishDeliveryCloud({
      delivery_cloud_id: deliveryCloudId,
      message: description,
    });
  };

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <DetailsPageHeader
        pageTitle={deliveryCloud?.name}
        backButtonTooltipText="Back to delivery clouds"
        deploymentStatus={deployments?.data?.[0]?.status}
        onBackClick={() => navigate(ROUTES.DELIVERY_CLOUDS)}
        onPublish={() => setPublishModalOpen(true)}
        isLoading={isLoading}
      />

      <DidomiTabs
        activeTab={activeTab}
        onActiveTabChange={({ detail: { active } }) => setActiveTab(active)}
      >
        <DidomiTabHeading variant="sub-level">
          <DidomiTabHeaders data-skeleton>
            {TAB_STEPS.map(step => (
              <DidomiTabHeader key={step.path} name={step.label} disabled={step.isDisabled} />
            ))}
          </DidomiTabHeaders>
        </DidomiTabHeading>
        <DidomiTabPanels className="py-m !overflow-x-visible">
          <DidomiTabPanel>
            <div className="space-y-xxs">
              <DeliveryCloudDetailsServices
                deliveryCloudId={deliveryCloudId}
                customDomains={deliveryCloud?.custom_domains}
              />
              <DeliveryCloudDetailsDomains
                deliveryCloudId={deliveryCloudId}
                baseDomain={deliveryCloud?.domain}
                customDomains={deliveryCloud?.custom_domains}
              />
            </div>
          </DidomiTabPanel>
          <DidomiTabPanel>
            <div className="space-y-xs">
              <DeliveryCloudDetailsDomains
                deliveryCloudId={deliveryCloudId}
                baseDomain={deliveryCloud?.domain}
                customDomains={deliveryCloud?.custom_domains}
              />
            </div>
          </DidomiTabPanel>
          <DidomiTabPanel>
            <div>
              {TAB_STEPS[activeTab].path === 'configuration' ? (
                <DeliveryCloudDetailsConfiguration deliveryCloud={deliveryCloud} />
              ) : null}
            </div>
          </DidomiTabPanel>
          <DidomiTabPanel>
            <div>
              {TAB_STEPS[activeTab].path === 'analytics' ? (
                <DeliveryCloudDetailsAnalytics deliveryCloud={deliveryCloud} />
              ) : null}
            </div>
          </DidomiTabPanel>
          <DidomiTabPanel>Logs</DidomiTabPanel>
        </DidomiTabPanels>
      </DidomiTabs>

      {isPublishModalOpen && (
        <PublishDeliveryCloud
          isOpen={isPublishModalOpen}
          deliveryCloud={deliveryCloud}
          onPublish={handlePublish}
          onCancel={() => setPublishModalOpen(false)}
        />
      )}

      <ProcessingSteps
        isOpen={isDeploying}
        title="Delivery cloud publication progress..."
        steps={[
          'Building delivery cloud assets',
          'Building service assets',
          'Preparing deployment',
          'Ready for deployment!',
        ]}
        onClose={() => {
          setIsDeploying(false);
          setDeploymentConfirmationOpen(true);
        }}
      />

      <DeploymentConfirmation
        isOpen={isDeploymentConfirmationOpen}
        onClose={() => setDeploymentConfirmationOpen(false)}
      />
    </DidomiSkeleton>
  );
};
