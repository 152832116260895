import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { DeliveryCloud } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDeliveryCloud = (deliveryCloudId: string) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchDeliveryCloud = async () => {
    const response = await axiosWithInterceptors.get<DeliveryCloud>(
      `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/delivery-clouds/${deliveryCloudId}`,
      {
        params: {
          organization_id: organizationId,
        },
      },
    );

    return response.data;
  };

  return useQuery<DeliveryCloud, AxiosError>(
    QUERY_KEYS.DELIVERY_CLOUD(deliveryCloudId),
    fetchDeliveryCloud,
    {
      enabled: !!deliveryCloudId,
    },
  );
};
