export const QUERY_KEYS = {
  QUOTAS: (orgId: string) => ['quotas', orgId],

  DELIVERY_CLOUDS: (orgId: string, extendWith?: string[]) => [
    'delivery-clouds',
    orgId,
    ...(extendWith || []),
  ],
  DELIVERY_CLOUDS_INFO: ['delivery-clouds-info'],

  DELIVERY_CLOUD: (cloudId: string) => ['delivery-cloud', cloudId],

  SERVICES: (deliveryCloudId: string) => ['services', deliveryCloudId],
  SERVICE: (serviceId: string) => ['service', serviceId],

  DOMAINS: (orgId: string) => ['domains', orgId],
  DOMAINS_FILTERED: (orgId: string, filters: Record<string, any>) => [
    'domains',
    orgId,
    ...Object.entries(filters).map(([key, value]) => `${key}:${value}`),
  ],

  DEPLOYMENTS_BY_DELIVERY_CLOUD: (deliveryCloudId: string, orgId: string) => [
    'deployments',
    deliveryCloudId,
    orgId,
  ],
};
