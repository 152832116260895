import React from 'react';

import { DidomiChip } from '@didomi/ui-atoms-react';
import { DeploymentStatus as DeploymentStatusConstant } from '@constants';

type ValueOf<T> = T[keyof T];

interface DeploymentStatusProps {
  status: ValueOf<typeof DeploymentStatusConstant> | undefined;
}

function mappingStatus(
  status: DeploymentStatusProps['status'],
): Pick<HTMLDidomiChipElement, 'label' | 'icon' | 'basicType'> {
  if (status === DeploymentStatusConstant.READY) {
    return {
      label: 'Ready',
      icon: 'reset-sm',
      basicType: 'info',
    };
  }

  if (status === DeploymentStatusConstant.RUNNING || DeploymentStatusConstant.SUCCESS) {
    return {
      label: status === DeploymentStatusConstant.RUNNING ? 'Running' : 'Success',
      icon: 'check-sm',
      basicType: 'success',
    };
  }

  return {
    label: 'Failure',
    icon: 'close-sm',
    basicType: 'error',
  };
}

export const DeploymentStatus = ({ status }: DeploymentStatusProps) => {
  if (!status) {
    return null;
  }

  const { label, icon, basicType } = mappingStatus(status);

  return <DidomiChip label={label} leftIcon={icon} basicType={basicType} />;
};
