import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  DidomiLoader,
  DidomiTabHeader,
  DidomiTabHeaders,
  DidomiTabHeading,
  DidomiTabPanel,
  DidomiTabPanels,
  DidomiTabs,
} from '@didomi/ui-atoms-react';

import { useDeliveryClouds } from '@hooks';

import { ROUTES } from '@utils';
import { ListDeliveryCloudsTab } from './components/ListDeliveryCloudsTab';

export const DeliveryCloudsList = () => {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds([
    'services',
    'deployments',
  ]);

  if (isLoadingDeliveryClouds) {
    return (
      <div className="mb-s flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  const deliveryClouds = data?.data;

  if (deliveryClouds.length === 0) {
    navigate(ROUTES.DELIVERY_CLOUDS_START);

    return null;
  }

  return (
    <div className="flex flex-row gap-x-20">
      <DidomiTabs activeTab={0}>
        <DidomiTabHeading variant="sub-level">
          <DidomiTabHeaders>
            <DidomiTabHeader name="Dashboard" />
          </DidomiTabHeaders>
        </DidomiTabHeading>
        <DidomiTabPanels className="py-m !overflow-x-visible">
          <DidomiTabPanel>
            <ListDeliveryCloudsTab deliveryClouds={deliveryClouds} />
          </DidomiTabPanel>
        </DidomiTabPanels>
      </DidomiTabs>
    </div>
  );
};
