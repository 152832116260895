import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiButton,
  DidomiModal,
  DidomiModalActions,
  DidomiModalContent,
  DidomiModalHeader,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

import { SPA_FULL_NAME } from '@constants';

interface DomainsByServiceProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DeploymentConfirmation = ({ isOpen, onClose }: DomainsByServiceProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);

  const handleOpenChange = e => {
    if (isOpen && !e.detail) {
      onClose();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} variant="slim" onOpenChange={handleOpenChange}>
      <div data-testid="deployment-confirmation-modal">
        <img
          src={`${ASSETS_URL}/assets/deployment-confirmation-img.png`}
          alt="Deployment confirmation"
          className={tx('w-[8.75rem] h-[8.75rem] m-auto mb-xs')}
        />
        <DidomiModalHeader
          variant="message"
          modalSubTitle="Your delivery cloud and its services will be up and running soon."
        />
        <DidomiModalContent>
          <p className={tx('body-small-semibold')}>The process may take up to 15 minutes.</p>
          <p className={tx('text-body-small')}>
            You can track the deployment progress next to the delivery cloud name in the top-left
            corner of your Console screen.
          </p>
        </DidomiModalContent>

        <DidomiModalActions>
          <DidomiButton variant="main" onClick={onClose}>
            Understood
          </DidomiButton>
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
