import React from 'react';

import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton, DidomiPromotionalPush } from '@didomi/ui-atoms-react';

import { SPA_FULL_NAME } from '@constants';

interface CtaProps {
  title: string | (() => React.ReactNode);
  description: string;
  actionText: string;
  onClick: VoidFunction;
}

export const Cta = ({ onClick, title, description, actionText }: CtaProps) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const renderProp = typeof title === 'string' ? title : title();

  return (
    <DidomiPromotionalPush variant="light" vertical className="max-w-[20.2488rem]">
      <img
        slot="img"
        src={`${ASSETS_URL}/assets/cta-image-profile.png`}
        alt=""
        className="h-[5.375rem] m-auto mb-s rounded-full border-[.3837rem] border-white"
      />
      <h4 slot="title" className="text-h3 leading-h3">
        {renderProp}
      </h4>
      <div slot="description">{description}</div>
      <DidomiButton slot="cta" variant="secondary-light" size="medium" onClick={onClick}>
        {actionText}
      </DidomiButton>
    </DidomiPromotionalPush>
  );
};
