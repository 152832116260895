import { AWSCloudProviderRegionId } from '@repo/cloud-providers';

import {
  ServiceType,
  type AvailableDeliveryCloudData,
  type UnavailableDeliveryCloudData,
} from '@types';

const regions = CONFIG.production
  ? [
      AWSCloudProviderRegionId.EU_WEST_3,
      AWSCloudProviderRegionId.US_WEST_1,
      AWSCloudProviderRegionId.AP_SOUTHEAST_1,
    ]
  : [AWSCloudProviderRegionId.EU_CENTRAL_1];

export const DELIVERY_CLOUDS_DEFAULT_DATA: (
  | AvailableDeliveryCloudData
  | UnavailableDeliveryCloudData
)[] = [
  {
    id: 'server-side-gtm',
    type: ServiceType.SGTM,
    path: '/sgtm',
    name: 'Server-side GTM',
    external_service_name: 'Google Tag Manager',
    service_name: 'sGTM Server',
    description: 'A GTM server for server-side tagging and measurement.',
    small_logo_url: 'services-assets/server-gtm/logo.png',
    big_logo_url: 'services-assets/server-gtm/gtm.png',
    is_available: true,
    regions: regions,
    createService: {
      title: 'Server Side GTM Service',
      description:
        'To create a server-side GTM service, you need to create a server container in Google Tag manager and get the Container Config token.',
      video: {
        url: 'services-assets/server-gtm/create-service-video.mp4',
        thumbnail: 'services-assets/server-gtm/create-service-preview.gif',
      },
      steps: [
        'Access your Google Tag Manager account',
        'Create  a new container & choose “<b>Server option</b>”',
        'Choose to “<b>manually provision tagging server</b>”',
        '<b>Copy the token</b> provided by GTM (Container Config)',
        '<b>Paste the token</b> into the container Container Config field',
      ],
    },
    connection: {
      title: 'Google Cloud connection with Didomi',
      description:
        'With the help of a Google Service Account connected to Didomi, you integrate server-side GTM with BigQuery and Firestore.',
      video: {
        url: 'services-assets/server-gtm/create-service-video.mp4',
        thumbnail: 'services-assets/server-gtm/create-service-preview.gif',
      },
      steps: [
        'Log into your <b>Google Cloud Platform</b> account',
        '<b>Select the project</b> you want to connect',
        '<b>Create a service account</b> in the IAM & Admin section',
        'In Service Accounts <b>open the KEYS section</b>',
        'Click <b>“Add key”</b>, <b>“Create new”</b> and select <b>JSON</b>',
        '<b>Upload here the JSON file downloaded</b>',
      ],
      documentation: {
        text: 'Google Cloud connection',
        href: 'https://docs.didomi.io/sgtm/google-cloud-connection/',
      },
    },
  },
  {
    id: 'server-side-cmp',
    type: 'cmp',
    name: 'Server-side CMP',
    description: 'A GTM server for server-side tagging and measurement.',
    small_logo_url: 'services-assets/didomi-cmp/logo.svg',
  },
] satisfies (AvailableDeliveryCloudData | UnavailableDeliveryCloudData)[];
