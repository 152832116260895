// src/enums/provider-id.enum.ts
var CloudProviderID = /* @__PURE__ */ function(CloudProviderID2) {
  CloudProviderID2["AWS"] = "aws";
  return CloudProviderID2;
}({});

// src/enums/cloud-provider-region-id.enum.ts
var AWSCloudProviderRegionId = /* @__PURE__ */ function(AWSCloudProviderRegionId2) {
  AWSCloudProviderRegionId2["AP_SOUTHEAST_1"] = "ap-southeast-1";
  AWSCloudProviderRegionId2["EU_CENTRAL_1"] = "eu-central-1";
  AWSCloudProviderRegionId2["EU_WEST_3"] = "eu-west-3";
  AWSCloudProviderRegionId2["US_EAST_1"] = "us-east-1";
  AWSCloudProviderRegionId2["US_WEST_1"] = "us-west-1";
  return AWSCloudProviderRegionId2;
}({});

// src/enums/cdn-id.enum.ts
var CdnId = /* @__PURE__ */ function(CdnId2) {
  CdnId2["CLOUDFRONT"] = "cloudfront";
  return CdnId2;
}({});

// src/providers.ts
var providers = [
  {
    id: CloudProviderID.AWS,
    name: "Amazon Web Services",
    regions: [
      {
        id: AWSCloudProviderRegionId.AP_SOUTHEAST_1,
        name: "Asia Pacific (Singapore)"
      },
      {
        id: AWSCloudProviderRegionId.EU_CENTRAL_1,
        name: "Europe (Frankfurt)"
      },
      {
        id: AWSCloudProviderRegionId.EU_WEST_3,
        name: "Europe (Paris)"
      },
      {
        id: AWSCloudProviderRegionId.US_EAST_1,
        name: "US East (N. Virginia)"
      },
      {
        id: AWSCloudProviderRegionId.US_WEST_1,
        name: "US West (N. California)"
      }
    ]
  }
];
export {
  AWSCloudProviderRegionId,
  CdnId,
  CloudProviderID,
  providers
};
