import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiHintbox,
  DidomiLoader,
} from '@didomi/ui-atoms-react';

import { CardSettingsInfo, SecondaryPageHeader } from '@components';
import { useDeliveryCloud, useService } from '@hooks';
import { DeliveryCloudCreated } from '@modals';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

const EMPTY_FIELD = '--';

export const ServiceDetails = () => {
  const navigate = useNavigate();
  const {
    [DYNAMIC_PARAMS.DELIVERY_CLOUD_ID]: deliveryCloudId,
    [DYNAMIC_PARAMS.SERVICE_ID]: serviceId,
  } = useParams();
  const { state } = useLocation();
  const [showDeliveryCloudCreatedModal, setShowDeliveryCloudCreatedModal] = useState(
    state?.isDeliveryCloudCreated,
  );

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);

  const {
    data: service,
    isLoading: isLoadingService,
    isError: isErrorService,
  } = useService(deliveryCloudId, serviceId);

  if (isLoadingDeliveryCloud || isLoadingService) {
    return (
      <div className="h-full flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  if (isErrorService) {
    navigate(ROUTES.DELIVERY_CLOUDS);

    return null;
  }

  if (state?.isDeliveryCloudCreated) {
    // NOTE: This is a hack to delete the state from the url
    navigate('.', { replace: true });

    return null;
  }

  const breadCrumbs = [{ label: 'Delivery Clouds' }, { label: deliveryCloud.name }];

  const settings = [
    {
      label: 'Name',
      value: service?.name,
    },
    {
      label: 'Path',
      value: service?.path,
    },
    {
      label: 'GTM container configuration',
      value: service?.parameters.sgtm_container_config,
    },
    {
      label: 'Google Cloud Platform',
      value: service?.parameters.sgtm_gcp_project_id,
    },
    {
      label: 'Description',
      value: service?.description,
    },
  ];

  return (
    <>
      <SecondaryPageHeader
        pageTitle={service?.name}
        breadCrumbs={breadCrumbs}
        backButtonTooltipText="Back to delivery cloud"
        onBackClick={() =>
          navigate(
            ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
              `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
              deliveryCloud.id,
            ),
          )
        }
      >
        <DidomiButton variant="secondary" size="medium" iconRight="timer">
          Logs
        </DidomiButton>
        <DidomiButton variant="secondary" size="medium" iconRight="analytics">
          Analytics
        </DidomiButton>
        <DidomiButton variant="top" size="medium" disabled>
          Publish
        </DidomiButton>
      </SecondaryPageHeader>

      {!deliveryCloud.custom_domains.length && (
        <DidomiHintbox variant="neutral" iconName="warning-blue" className="mb-m">
          Add a custom domain to your Delivery Cloud to route all GTM requests through your own
          first-party domain.
          <br />
          This helps prevent data collection from being blocked by tracking prevention or ad
          blockers.
          <div slot="info">
            <DidomiButton
              variant="main"
              size="small"
              onClick={() =>
                navigate(
                  ROUTES.DOMAINS.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId),
                )
              }
            >
              Add domain
            </DidomiButton>
          </div>
        </DidomiHintbox>
      )}

      <DidomiCardContainer
        style={{
          '--card-inner-padding': 0,
          '--card-outer-padding': '24px',
          '--card-inner-bg': 'none',
          '--card-outer-bg': '#F9FAFA',
          '--card-border': 'none',
        }}
      >
        <div className="flex flex-row justify-between items-center mb-s">
          <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">Description</p>
          <DidomiButton
            variant="main"
            size="small"
            onClick={() =>
              navigate(
                ROUTES.SERVICE_EDIT.replace(
                  `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
                  deliveryCloudId,
                ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, serviceId),
              )
            }
          >
            Edit settings
          </DidomiButton>
        </div>
        <div className="grid grid-cols-2 gap-xxs">
          {settings.map(setting => (
            <CardSettingsInfo key={setting.label} title={setting.label}>
              {setting.value ?? EMPTY_FIELD}
            </CardSettingsInfo>
          ))}
        </div>
      </DidomiCardContainer>

      <DeliveryCloudCreated
        isOpen={showDeliveryCloudCreatedModal}
        domain={deliveryCloud.domain}
        onAddDomains={() =>
          navigate(
            ROUTES.DOMAINS_START.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId),
          )
        }
        onClose={() => setShowDeliveryCloudCreatedModal(false)}
      />
    </>
  );
};
