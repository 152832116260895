import React from 'react';
import { DidomiCardContainer, DidomiIconButton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tw } from '@twind/core';

import { Service } from '@types';

interface ServiceOverviewProps {
  logoSrc: string;
  service: Service;
  showEdit?: boolean;
  withDomainsList?: boolean;
  onEdit: (e: React.MouseEvent<HTMLDidomiIconButtonElement>) => void;
  onPreview: (e: React.MouseEvent<HTMLDidomiIconButtonElement>) => void;
  onShowDomainsByService: (e: React.MouseEvent<HTMLDidomiIconButtonElement>) => void;
}

export const ServiceOverview = ({
  logoSrc,
  service,
  showEdit,
  withDomainsList = false,
  onEdit,
  onPreview,
  onShowDomainsByService,
}: ServiceOverviewProps) => {
  return (
    <>
      <DidomiCardContainer
        style={{
          '--card-outer-padding': '0',
          '--card-inner-padding': tw.theme('spacing.xs'),
          '--card-outer-bg': tw.theme('colors.white'),
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div className="grid grid-cols-[1fr_1fr_1fr_auto] items-center">
          <div className="flex flex-row items-center gap-x-xxs">
            <div className="flex flex-col mr-xxs">
              <img className="mx-auto h-s w-s" src={logoSrc} alt={service.name} />
            </div>
            <div>
              <p className="text-body-small font-semibold text-primary-blue-6">{service.name}</p>
              <p className="text-body-extra-small text-neutral-gray-6">{service.path}</p>
            </div>

            {withDomainsList && (
              <div className="ml-auto mr-s">
                <DidomiTooltip placement="top" content="View service domains">
                  <DidomiIconButton
                    icon="add-sm"
                    variant="rounded"
                    size="small"
                    onClick={onShowDomainsByService}
                  />
                </DidomiTooltip>
              </div>
            )}
          </div>

          <div>
            <p className="text-body-small font-semibold text-primary-blue-6">
              Request <b className="font-semibold">1 654 987 675</b>
            </p>
            <p className="text-body-extra-small text-neutral-gray-6">Over the last 7 days</p>
          </div>

          <div className="flex flex-row">
            <div>
              <p className="text-body-small font-semibold text-primary-blue-6">
                Average success rate <b className="font-semibold">89%</b>
              </p>
              <p className="text-body-extra-small text-neutral-gray-6">Over the last 4 days</p>
            </div>
          </div>

          <div className="flex flex-row justify-end gap-x-xxs">
            {showEdit && (
              <DidomiTooltip placement="top" content="Edit service">
                <DidomiIconButton icon="edit" variant="rounded" size="small" onClick={onEdit} />
              </DidomiTooltip>
            )}
            <DidomiTooltip placement="top" content="View service">
              <DidomiIconButton icon="preview" variant="rounded" size="small" onClick={onPreview} />
            </DidomiTooltip>
          </div>
        </div>
      </DidomiCardContainer>
    </>
  );
};
