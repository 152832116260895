import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import React from 'react';

import { useActiveOrganization } from '@didomi/utility-react';

import { ErrorState } from '@components';
import { BasicLayout, HomeLayout } from '@layouts';

import {
  DomainAdd,
  DeliveryCloudsList,
  DeliveryCloudDetails,
  ServiceEdit,
  DeliveryCloudStart,
  DeliveryCloudStartByType,
  DeliveryCloudCreateByType,
  ServiceDetails,
} from '@pages';

import { ROUTES } from '@utils';

const getRouter = (basename: string) => {
  return createBrowserRouter(
    [
      {
        element: <BasicLayout />,
        errorElement: <ErrorState />,
        children: [
          {
            index: true,
            element: <Navigate replace to={ROUTES.DELIVERY_CLOUDS} />,
          },
          {
            element: <HomeLayout />,
            errorElement: <ErrorState />,
            children: [
              {
                path: ROUTES.DELIVERY_CLOUDS,
                element: <DeliveryCloudsList />,
              },
              {
                path: ROUTES.DELIVERY_CLOUDS_START,
                element: <DeliveryCloudStart />,
              },
            ],
          },
          {
            path: ROUTES.DELIVERY_CLOUDS_START_TYPE,
            element: <DeliveryCloudStartByType />,
          },
          {
            path: ROUTES.DELIVERY_CLOUDS_DETAILS,
            element: <DeliveryCloudDetails />,
          },
          {
            path: ROUTES.SERVICE_ADD,
            element: <DeliveryCloudCreateByType backRoute={ROUTES.DELIVERY_CLOUDS_DETAILS} />,
          },
          {
            path: ROUTES.SERVICE_ADD_TYPE,
            element: <DeliveryCloudStartByType />,
          },
          {
            path: ROUTES.DOMAINS_START,
            element: <DomainAdd />,
          },
          {
            path: ROUTES.DELIVERY_CLOUDS_CREATE,
            element: <DeliveryCloudCreateByType backRoute={ROUTES.DELIVERY_CLOUDS} />,
          },
          {
            path: ROUTES.DELIVERY_CLOUDS_CREATE_TYPE,
            element: <DeliveryCloudStartByType />,
          },
          {
            path: ROUTES.SERVICE_DETAILS,
            element: <ServiceDetails />,
          },
          {
            path: ROUTES.SERVICE_EDIT,
            element: <ServiceEdit />,
          },
        ],
      },
    ],
    { basename },
  );
};

export const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();

  const basename = `/${organizationId}/server-side`;

  return <RouterProvider router={getRouter(basename)} />;
};
