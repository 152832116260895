import React, { useEffect, useState } from 'react';
import {
  DidomiModal,
  DidomiLoader,
  DidomiModalHeader,
  DidomiModalContent,
  DidomiIcon,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface ProcessingStepsProps {
  isOpen: boolean;
  title: string;
  description?: string;
  steps: string[];
  onClose: () => void;
}

export const ProcessingSteps = ({
  isOpen,
  title,
  description,
  steps,
  onClose,
}: ProcessingStepsProps) => {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(1);
      return;
    }

    const interval = setInterval(() => {
      setActiveStep(prev => {
        if (prev >= steps.length) {
          clearInterval(interval);
          onClose();

          return prev;
        }

        return prev + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [isOpen, steps, onClose]);

  const getIconForStep = (step: number) => {
    if (step < activeStep) {
      return 'success-medium';
    }
    return 'status-empty';
  };

  return (
    <DidomiModal isOpen={isOpen} variant="full-screen" permanent>
      <DidomiLoader className={tx('pb-xs')} />
      <DidomiModalHeader
        className="mt-xs mb-xxs"
        modalSubTitle={title}
        modalDescription={description}
      />

      <DidomiModalContent>
        <ul className={tx('flex flex-col gap-y-xxs')}>
          {steps.map((step, index) => (
            <li key={index} className={tx('flex flex-row items-center gap-x-xxs')}>
              <DidomiIcon name={getIconForStep(index)} className={tx('w-3.5')} />
              <p className={tx('text-body-normal text-primary-blue-6')}>{step}</p>
            </li>
          ))}
        </ul>
      </DidomiModalContent>
    </DidomiModal>
  );
};
