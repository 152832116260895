import React, { useState } from 'react';
import {
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiButtonGroup,
} from '@didomi/ui-atoms-react';

type UnsavedChangesProps = {
  isOpen?: boolean;
  onSave?: () => Promise<void>;
  onDiscardChanges: () => void;
};

export const UnsavedChanges = ({
  isOpen = false,
  onSave,
  onDiscardChanges,
}: UnsavedChangesProps) => {
  const [isSaving, setIsSaving] = useState(isOpen);

  const discardAndProceed = () => {
    onDiscardChanges();
  };

  const handleSave = async () => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
  };

  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className="mb-s" name="warning-modal" />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle="Pay attention"
        modalTitle="You have unsaved changes!"
        modalDescription="If you don’t save now, your recent changes will be lost."
      />

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={discardAndProceed}>
            Dont Save
          </DidomiButton>

          <DidomiButton variant="top" iconRight="save" onClick={handleSave}>
            Save
          </DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
