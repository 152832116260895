import { useNavigate } from 'react-router-dom';
import React from 'react';
import {
  DidomiTabHeader,
  DidomiTabHeaders,
  DidomiTabHeading,
  DidomiTabPanel,
  DidomiTabPanels,
  DidomiTabs,
  DidomiSkeleton,
} from '@didomi/ui-atoms-react';

import { useDeliveryClouds } from '@hooks';
import { ROUTES } from '@utils';

import { ListDeliveryCloudsTab } from './components/ListDeliveryCloudsTab';

export const DeliveryCloudsList = () => {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds([
    'services',
    'deployments',
  ]);

  const deliveryClouds = data?.data;

  if (!isLoadingDeliveryClouds && deliveryClouds.length === 0) {
    navigate(ROUTES.DELIVERY_CLOUDS_START);

    return null;
  }

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoadingDeliveryClouds}>
      <DidomiTabs activeTab={0}>
        <DidomiTabHeading variant="sub-level">
          <DidomiTabHeaders data-skeleton>
            <DidomiTabHeader name="Dashboard" />
          </DidomiTabHeaders>
        </DidomiTabHeading>
        <DidomiTabPanels className="py-m !overflow-x-visible">
          <DidomiTabPanel>
            <ListDeliveryCloudsTab deliveryClouds={deliveryClouds} />
          </DidomiTabPanel>
        </DidomiTabPanels>
      </DidomiTabs>
    </DidomiSkeleton>
  );
};
