import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Service } from '@types';
import { randomPath } from '@utils';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

type ServiceData = Pick<Service, 'name' | 'type' | 'path' | 'delivery_cloud_id' | 'parameters'>;

export function useCreateService(
  options?: UseMutationOptions<
    AxiosResponse<Service>,
    AxiosError<{ message: string }>,
    ServiceData
  >,
) {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (data: ServiceData) =>
      axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}server-side/services`,
        {
          ...data,
          path: randomPath(),
        },
        {
          params: {
            organization_id: organizationId,
          },
        },
      ),
    {
      ...options,
      onSuccess: async (response: AxiosResponse<Service>, variables, context) => {
        // NOTE: QUERY_KEYS.DELIVERY_CLOUDS with services has the last list of
        // services, before the creation of the new one, so we need to invalidate
        // it to force to refetch it
        const queryKeys = [
          QUERY_KEYS.SERVICES(variables.delivery_cloud_id),
          QUERY_KEYS.DELIVERY_CLOUDS(organizationId, ['services', 'deployments']),
        ];

        for (const key of queryKeys) {
          const hasObservers = queryClient.getQueryCache().find(key)?.getObserversCount() > 0;
          if (hasObservers) {
            await queryClient.invalidateQueries(key);
          } else {
            await queryClient.refetchQueries(key);
          }
        }

        queryClient.setQueryData(QUERY_KEYS.SERVICE(response.data.id), response.data);

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
}
