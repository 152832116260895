import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { DeliveryCloud } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

type DeliveryCloudData = Pick<DeliveryCloud, 'name' | 'regions'>;

export function useCreateDeliveryCloud(
  options?: UseMutationOptions<
    AxiosResponse<DeliveryCloud>,
    AxiosError<{ message: string }>,
    DeliveryCloudData
  >,
) {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (data: DeliveryCloudData) =>
      axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}server-side/delivery-clouds`,
        data,
        {
          params: {
            organization_id: organizationId,
          },
        },
      ),
    {
      ...options,
      onSuccess: async (response: AxiosResponse<DeliveryCloud>, variables, context) => {
        const hasObservers =
          queryClient
            .getQueryCache()
            .find(QUERY_KEYS.DELIVERY_CLOUDS(organizationId))
            ?.getObserversCount() > 0;

        if (hasObservers) {
          await queryClient.invalidateQueries(QUERY_KEYS.DELIVERY_CLOUDS(organizationId));
        } else {
          await queryClient.refetchQueries(QUERY_KEYS.DELIVERY_CLOUDS(organizationId));
        }

        queryClient.setQueryData(QUERY_KEYS.DELIVERY_CLOUD(response.data.id), response.data);

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
}
