import { PaginatedResponse } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { UseQueryOptions, useQuery } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Service } from '@types';

import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useServices = (
  deliveryCloudId: string,
  options?: UseQueryOptions<PaginatedResponse<Service>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchService = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Service>>(
      `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/services`,
      {
        params: {
          organization_id: organizationId,
        },
      },
    );

    const hasServices = response.data?.data.find(
      service => service.delivery_cloud_id === deliveryCloudId,
    );
    if (!hasServices) {
      throw new Error('Service not found');
    }

    return response.data;
  };

  return useQuery(QUERY_KEYS.SERVICES(deliveryCloudId), fetchService, {
    ...options,
    enabled: !!deliveryCloudId,
  });
};
