import { type DeploymentStatus as DeploymentStatusEntity } from '@repo/entities';

type ValueOf<T> = T[keyof T];

export const DeploymentStatus = {
  READY: 'ready',
  RUNNING: 'running',
  SUCCESS: 'success',
  FAILURE: 'failure',
} as const satisfies Record<keyof typeof DeploymentStatusEntity, ValueOf<DeploymentStatusEntity>>;
