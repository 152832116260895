import React, { useState, useRef } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import {
  DidomiChip,
  DidomiLargePush,
  DidomiModal,
  DidomiModalContent,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

import { SectionTitle, VideoPreview } from '@components';
import { SPA_FULL_NAME } from '@constants';
import { ServiceDocumentationData } from '@types';

export const SidebarInfoSteps = ({
  title,
  description,
  video,
  steps = [],
  documentation,
  withHeaderImage = false,
}: ServiceDocumentationData & { withHeaderImage?: boolean }) => {
  const ASSETS_URL = useSPAAssetsUrl(SPA_FULL_NAME);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleOpenChange = e => {
    if (isVideoModalOpen && !e.detail) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;

      setIsVideoModalOpen(false);
    }
  };

  return (
    <>
      {withHeaderImage && (
        <div className="absolute top-s right-s w-[3.75rem] h-auto">
          <img
            src={`${ASSETS_URL}/assets/service-add/service-add-sidebar-icon.png`}
            alt={`Add service ${title}`}
          />
        </div>
      )}

      <div className="space-y-s">
        <div className={tx({ 'mr-l': withHeaderImage })}>
          <SectionTitle name={title} description={description} />
        </div>
        <div>
          <VideoPreview
            preview={`${ASSETS_URL}/assets/${video?.thumbnail}`}
            alt={`${title} service`}
            onPreviewClick={() => setIsVideoModalOpen(true)}
          />
        </div>
        <ol className="space-y-xs">
          {steps.map((step, index) => (
            <li key={index} className="flex flex-row items-baseline gap-x-xxs text-primary-blue-6">
              <DidomiChip label={(index + 1).toString()} />
              <p
                className="text-body-small font-normal"
                dangerouslySetInnerHTML={{ __html: step }}
              />
            </li>
          ))}
        </ol>

        {!!documentation?.href && (
          <DidomiLargePush
            className="w-full"
            text="Documentation"
            highlightedText={documentation.text}
            icon="export"
            href={documentation.href}
            target="_blank"
          />
        )}
      </div>

      <DidomiModal
        isOpen={isVideoModalOpen}
        closable
        onOpenChange={handleOpenChange}
        variant="fullscreen"
        modification="transparent"
      >
        <DidomiModalContent className="text-primary-blue-6 h-full">
          <div className={tx('rounded-lg w-1/2 h-full m-auto grid place-content-center')}>
            <video controls className={tx('rounded-lg overflow-hidden')} ref={videoRef}>
              <source src={`${ASSETS_URL}/assets/${video?.url}#t=0.1`} type="video/mp4" />
              <track kind="captions" src={`${ASSETS_URL}/assets/${video?.url}`} />
              Your browser does not support the video tag.
            </video>
          </div>
        </DidomiModalContent>
      </DidomiModal>
    </>
  );
};
