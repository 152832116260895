import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QUERY_KEYS } from '@constants';
import { Deployment } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

type DeploymentData = {
  message?: Deployment['message'];
  delivery_cloud_id: Deployment['delivery_cloud_id'];
};

export function useCreateDeployment(
  options?: UseMutationOptions<
    AxiosResponse<Deployment>,
    AxiosError<{ message: string }>,
    DeploymentData
  >,
) {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (data: DeploymentData) =>
      axiosWithInterceptors.post(
        `${CONFIG.environmentConfig.apiBaseUrl}server-side/deployments`,
        data,
        {
          params: {
            organization_id: organizationId,
          },
        },
      ),
    {
      ...options,
      onSuccess: async (response: AxiosResponse<Deployment>, variables, context) => {
        const queryKeys = [
          QUERY_KEYS.DEPLOYMENTS_BY_DELIVERY_CLOUD(variables.delivery_cloud_id, organizationId),
          QUERY_KEYS.DELIVERY_CLOUDS(organizationId, ['services', 'deployments']),
        ];

        for (const key of queryKeys) {
          const hasObservers = queryClient.getQueryCache().find(key)?.getObserversCount() > 0;
          if (hasObservers) {
            await queryClient.invalidateQueries(key);
          } else {
            await queryClient.refetchQueries(key);
          }
        }

        queryClient.setQueryData(QUERY_KEYS.DELIVERY_CLOUD(response.data.id), response.data);

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
}
