import React from 'react';
import {
  DidomiIllustration,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiButton,
  DidomiModalContent,
  DidomiCardContainer,
  DidomiTextarea,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { tx } from '@twind/core';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Form } from '@components';
import { CDN_PROVIDERS, REGIONS } from '@constants';
import { DeliveryCloud } from '@types';

type DeleteDomainModalProps = {
  isOpen: boolean;
  deliveryCloud: DeliveryCloud;
  onPublish?: ({ description }: { description?: string }) => void;
  onCancel?: () => void;
};

const publishDeliveryCloudSchema = z.object({
  description: z.string().trim().nullable(),
});

export const PublishDeliveryCloud = ({
  isOpen,
  deliveryCloud,
  onPublish,
  onCancel,
}: DeleteDomainModalProps) => {
  const methods = useForm({
    defaultValues: {
      description: '',
    },
    mode: 'onChange',
    resolver: zodResolver(publishDeliveryCloudSchema),
  });

  const handleCancel = () => {
    methods.reset();
    onCancel?.();
  };

  const handlePublishSubmit = (data: z.infer<typeof publishDeliveryCloudSchema>) =>
    onPublish?.(data);

  const descriptionController = methods.register('description');

  return (
    <DidomiModal isOpen={isOpen} permanent>
      <Form methods={methods} onSubmit={handlePublishSubmit}>
        <DidomiIllustration className={tx('mb-xs')} name="warning-modal" />
        <DidomiModalHeader
          variant="warning"
          modalTitle="All the following changes will be deployed"
          modalSubTitle="You are going to publish a new version"
        />
        <DidomiModalContent className="w-full text-left">
          <div className={tx('space-y-xs my-m')}>
            <CardInfo title="Name" value={deliveryCloud.name} />
            <CardInfo
              title="Region"
              value={deliveryCloud.regions.map(region => REGIONS[region]).join(' - ')}
            />
            <CardInfo title="CDN" value={CDN_PROVIDERS[deliveryCloud.cdn]} />
            <CardInfo title="Domains" value={deliveryCloud.custom_domains.join(' - ')} />
          </div>

          <DidomiTextarea
            id="deployment-description-text-input"
            className="block w-full"
            label="Publication description"
            description="Optional"
            placeholder="e.g.: Delivery cloud name, Services added, domains added..."
            error={methods.formState.errors?.description?.message}
            {...descriptionController}
            onValueChange={e => {
              methods.setValue('description', e.detail);
            }}
          />
        </DidomiModalContent>

        <DidomiModalActions>
          <DidomiButton variant="secondary" onClick={handleCancel}>
            Cancel
          </DidomiButton>

          <DidomiButton variant="top" iconRight="save-publish" type="submit">
            Publish
          </DidomiButton>
        </DidomiModalActions>
      </Form>
    </DidomiModal>
  );
};

const CardInfo = ({ title, value }: { title: string; value: string }) => {
  return (
    <DidomiCardContainer
      style={{
        '--card-outer-padding': '0',
        '--card-inner-padding': '0.5rem 1rem',
        '--card-outer-bg': '#F9FAFA',
        '--card-inner-bg': 'none',
        '--card-border': 'none',
      }}
    >
      <p className={tx('text-body-extra-small text-neutral-gray-7')}>{title}</p>
      <p className={tx('text-body-small font-semibold text-primary-blue-6')}>{value}</p>
    </DidomiCardContainer>
  );
};
