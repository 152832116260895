import { useSearchParams } from 'react-router-dom';
import { useState, useEffect, useCallback, useMemo } from 'react';

interface Step {
  path: string;
  label: string;
  isDisabled?: boolean;
}

type UseTabUrlProps = {
  steps: Step[];
  queryParam?: string;
  selectedTab?: number;
};

export const useTabUrl = ({ steps, queryParam = 'step', selectedTab = 0 }: UseTabUrlProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const stepsMap = useMemo(
    () => new Map(steps.map((step, index) => [step.path.toLowerCase(), index])),
    [steps],
  );

  const [activeTab, setActiveTab] = useState<number>(() => {
    const tab = searchParams.get(queryParam);

    if (!tab) {
      return selectedTab;
    }

    const normalizedTab = tab.toLowerCase();
    if (!stepsMap.has(normalizedTab)) {
      return selectedTab;
    }

    return stepsMap.get(normalizedTab) as number;
  });

  useEffect(() => {
    const tab = searchParams.get(queryParam)?.toLowerCase();

    if (tab && stepsMap.has(tab)) {
      setActiveTab(stepsMap.get(tab)!);
    } else {
      setActiveTab(selectedTab);
    }
  }, [searchParams, stepsMap, queryParam, selectedTab]);

  const setActiveStep = useCallback(
    (tabIndex: number) => {
      if (tabIndex < 0 || tabIndex >= steps.length || steps[tabIndex].isDisabled) {
        throw new Error('Invalid tab index');
      }

      const newSearchParams = new URLSearchParams(searchParams);

      if (tabIndex === activeTab) {
        return;
      }

      if (tabIndex === 0) {
        newSearchParams.delete(queryParam);
      } else {
        newSearchParams.set(queryParam, steps[tabIndex].path.toLowerCase());
      }

      setSearchParams(newSearchParams, { replace: true });
    },
    [steps, searchParams, activeTab, setSearchParams, queryParam],
  );

  return { activeTab, setActiveTab: setActiveStep };
};
