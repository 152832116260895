import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DidomiLoader } from '@didomi/ui-atoms-react';

import { Cta, DeliveryCloudList } from '@components';
import { useDeliveryClouds, useDeliveryCloudsDefaultData } from '@hooks';
import { ROUTES } from '@utils';

const mapDeliveryCloudToListItem = (service: any) => ({
  id: service.id,
  type: service.type,
  name: service.name,
  description: service.description,
  isAvailable: service.is_available,
  logoSrc: service.small_logo_url,
});

const RenderDeliveryCloudSection = ({
  children,
  clouds,
  title,
  showCount = true,
}: React.PropsWithChildren<{
  clouds: any[];
  title: string;
  showCount?: boolean;
}>) =>
  clouds.length > 0 ? (
    <div>
      <p className="pb-xxs gap-x-l font-semibold text-body-normal text-secondary-cobalt-blue-4">
        {title} {showCount && `(${clouds.length})`}
      </p>
      {children}
    </div>
  ) : null;

export const DeliveryCloudStart = () => {
  const navigate = useNavigate();
  const { data, isLoading: isLoadingDeliveryClouds } = useDeliveryClouds();
  const { deliveryCloudsDefaultData } = useDeliveryCloudsDefaultData();

  if (isLoadingDeliveryClouds) {
    return (
      <div className="mb-s flex justify-center items-center">
        <DidomiLoader />
      </div>
    );
  }

  const deliveryClouds = data?.data;

  if (deliveryClouds.length) {
    navigate(ROUTES.DELIVERY_CLOUDS);

    return null;
  }

  const availableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => deliveryCloud.is_available,
  );
  const unavailableDeliveryClouds = deliveryCloudsDefaultData.filter(
    deliveryCloud => !deliveryCloud.is_available,
  );

  return (
    <div className="flex flex-row gap-20">
      <div className="grow flex flex-col gap-y-10">
        <RenderDeliveryCloudSection clouds={availableDeliveryClouds} title="Available now">
          <DeliveryCloudList
            deliveryClouds={[...availableDeliveryClouds].map(mapDeliveryCloudToListItem)}
            variant="block"
            actionText="Start"
          />
        </RenderDeliveryCloudSection>

        <RenderDeliveryCloudSection clouds={unavailableDeliveryClouds} title="Coming soon">
          <DeliveryCloudList
            deliveryClouds={[...unavailableDeliveryClouds].map(mapDeliveryCloudToListItem)}
            variant="block"
            actionText="I'm interested"
            showComingSoon
          />
        </RenderDeliveryCloudSection>
      </div>
      <div className="flex-shrink-0">
        <Cta
          title={() => (
            <>
              Don&apos;t know
              <br />
              where to start?
            </>
          )}
          description="Reach out to your Account Manager to explore the benefits of our Server-side hosting solutions!"
          actionText="Contact us"
          onClick={() => {}}
        />
      </div>
    </div>
  );
};
