import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiHintbox,
  DidomiSkeleton,
  DidomiTextarea,
  DidomiTextInput,
} from '@didomi/ui-atoms-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { tw, tx } from '@twind/core';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Form, SecondaryPageHeader, Sidebar, SidebarInfoSteps } from '@components';
import {
  useCustomSnackbar,
  useDeliveryCloud,
  useDeliveryCloudsDefaultData,
  useEditService,
  useService,
} from '@hooks';

import { UnsavedChanges } from '@modals';
import { AvailableDeliveryCloudData } from '@types';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';
import { InputUpload } from 'components/InputUpload/InputUpload';
import { ServiceEditActions } from './components/ServiceEditFormActions';

const editServiceSchema = z.object({
  name: z
    .string({
      invalid_type_error: 'Name is required',
    })
    .trim()
    .min(1, { message: 'Name is required' }),
  description: z.string().trim().nullable(),
  path: z
    .string({
      invalid_type_error: 'Name is required',
    })
    .trim()
    .min(1, { message: 'Path is required' }),
  projectId: z.string().trim().optional(),
  json: z.string().trim().optional(),
});

export const ServiceEdit = () => {
  const navigate = useNavigate();
  const { deliveryCloudId, serviceId } = useParams();
  const [isUnsavedChangesOpen, setIsUnsavedChangesOpen] = useState(false);

  const { displayApiErrorSnackbar, displaySnackbar } = useCustomSnackbar();

  const { data: deliveryCloud, isLoading: isLoadingDeliveryCloud } =
    useDeliveryCloud(deliveryCloudId);
  const { data: service, isLoading: isLoadingService } = useService(deliveryCloudId, serviceId);
  const { deliveryCloudDefaultData } = useDeliveryCloudsDefaultData<AvailableDeliveryCloudData>(
    service?.type,
  );

  const { mutateAsync: editService } = useEditService(serviceId);

  const methods = useForm({
    values: {
      name: service?.name,
      description: service?.description,
      path: service?.path,
      projectId: service?.parameters.sgtm_gcp_project_id,
      json: undefined,
    },
    mode: 'onChange',
    resolver: zodResolver(editServiceSchema),
  });

  const isLoading = isLoadingDeliveryCloud || isLoadingService;

  const breadCrumbs = [{ label: 'Delivery Clouds' }, { label: deliveryCloud?.name }];

  const serviceDetailsRoute = ROUTES.SERVICE_DETAILS.replace(
    `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
    deliveryCloudId,
  ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, service?.id);

  const handleFileChange = (content?: { project_id: string }) => {
    methods.setValue('projectId', content?.project_id, { shouldValidate: true });
    methods.setValue('json', content ? JSON.stringify(content) : undefined);
  };

  const handleCancelForm = () => {
    if (Object.keys(methods.formState.dirtyFields).length) {
      return setIsUnsavedChangesOpen(true);
    }

    navigate(serviceDetailsRoute);
  };

  const handleServiceSubmit = async (data: z.infer<typeof editServiceSchema>) => {
    const { projectId, json, ...rest } = data;
    try {
      await editService({
        ...rest,
        type: service?.type,
        parameters: {
          sgtm_gcp_service_account_key_json: json,
          sgtm_gcp_project_id: projectId,
          sgtm_container_config: service?.parameters.sgtm_container_config,
        },
      });

      displaySnackbar('Service configuration has been updated', { icon: 'check' });
      navigate(serviceDetailsRoute);
    } catch (error) {
      displayApiErrorSnackbar(error);
    }
  };

  const nameController = methods.register('name');
  const descriptionController = methods.register('description');
  const projectId = methods.watch('projectId');

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoading}>
      <SecondaryPageHeader
        pageTitle={deliveryCloud?.name}
        breadCrumbs={breadCrumbs}
        backButtonTooltipText="Back to delivery cloud"
        onBackClick={() =>
          navigate(
            ROUTES.DELIVERY_CLOUDS_DETAILS.replace(
              `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
              deliveryCloudId,
            ),
          )
        }
      >
        <DidomiButton variant="secondary" size="medium" iconRight="timer" data-skeleton>
          Logs
        </DidomiButton>
        <DidomiButton variant="secondary" size="medium" iconRight="analytics" data-skeleton>
          Analytics
        </DidomiButton>
        <DidomiButton variant="top" size="medium" disabled data-skeleton>
          Publish
        </DidomiButton>
      </SecondaryPageHeader>

      <>
        <div className="flex flex-row gap-x-l">
          <DidomiCardContainer
            style={{
              '--card-outer-padding': '0',
              '--card-inner-padding': tw.theme('spacing.s'),
              '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
              '--card-inner-bg': 'none',
              '--card-border': 'none',
            }}
          >
            <Form methods={methods} onSubmit={handleServiceSubmit}>
              <div className="flex flex-row justify-between items-center mb-xs">
                <p
                  className="font-semibold text-body-normal text-secondary-cobalt-blue-4"
                  data-skeleton
                >
                  Settings
                </p>
                <ServiceEditActions onCancel={handleCancelForm} />
              </div>

              <div className="space-y-xs">
                <fieldset className="space-y-xxs">
                  <DidomiTextInput
                    className="block w-full"
                    label="Name"
                    placeholder="GTM-MWZZ08WD"
                    required
                    error={methods.formState.errors?.name?.message}
                    {...nameController}
                    onValueChange={nameController.onChange}
                    data-skeleton
                  />
                </fieldset>
                <fieldset className="space-y-xxs">
                  <DidomiTextarea
                    className="block w-full"
                    label="Description"
                    description="Optional"
                    placeholder="Enter a service description here..."
                    error={methods.formState.errors?.description?.message}
                    {...descriptionController}
                    onValueChange={e => {
                      methods.setValue('description', e.detail);
                    }}
                    data-skeleton
                  />
                </fieldset>
                <fieldset className="space-y-xxs">
                  <DidomiHintbox
                    titleText="Container Configuration"
                    variant="neutral"
                    data-skeleton
                  >
                    This Container Config token is provided by Google when you are setting up your
                    tagging server in Google Tag Manager.
                  </DidomiHintbox>
                  <DidomiTextInput
                    className="block w-full"
                    label="GTM Container Configuration"
                    required
                    disabled
                    value={service?.parameters.sgtm_container_config}
                    data-skeleton
                  />
                </fieldset>
                <fieldset className="space-y-xxs">
                  <DidomiHintbox titleText="Path" variant="neutral" data-skeleton>
                    A unique path on the Delivery Cloud that routes your requests to the service
                    through the configured domains.
                  </DidomiHintbox>
                  <DidomiTextInput
                    className="block w-full"
                    label="Path"
                    error={methods.formState.errors?.path?.message}
                    {...{
                      ...methods.register('path'),
                      onValueChange: methods.register('path').onChange,
                    }}
                    data-skeleton
                  />
                </fieldset>
                <fieldset className="space-y-xxs">
                  <DidomiHintbox titleText="Google Cloud Platform" variant="neutral" data-skeleton>
                    Upload the JSON file generated from your Google Cloud Platform project to
                    connect BigQuery, Firestore, or both to your sGTM instance. To generate the
                    file, create a Service Account user for the relevant services in your Google
                    Cloud Platform project.
                  </DidomiHintbox>

                  <InputUpload
                    activeText="Turn off to deactivate Google services"
                    inactiveText="Turn on to activate Google services"
                    value={projectId}
                    onFileChange={handleFileChange}
                  />
                  {methods.formState.errors?.projectId?.message && (
                    <p className={tx('input-error')}>
                      {methods.formState.errors?.projectId?.message}
                    </p>
                  )}
                </fieldset>

                <div className="flex flex-row justify-between items-center">
                  <p className="text-body-extra-small text-primary-blue-6">* Fields are required</p>

                  <ServiceEditActions onCancel={handleCancelForm} />
                </div>
              </div>
            </Form>
          </DidomiCardContainer>

          {isLoading ? (
            <Sidebar className="relative w-full h-[44.5rem] [&>[data-skeleton]]:h-full" />
          ) : (
            <Sidebar className="relative">
              <SidebarInfoSteps {...deliveryCloudDefaultData?.connection} />
            </Sidebar>
          )}
        </div>

        <UnsavedChanges
          isOpen={isUnsavedChangesOpen}
          onSave={() => handleServiceSubmit(methods.getValues())}
          onDiscardChanges={() => navigate(serviceDetailsRoute)}
        />
      </>
    </DidomiSkeleton>
  );
};
