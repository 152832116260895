import React from 'react';
import { DidomiBackButtonLink, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';

import { BreadCrumbs } from '@components';
import { EMPTY_FIELD } from '@constants';
import { BreadCrumb } from '@types';

interface SecondaryPageHeaderProps {
  pageTitle: string;
  backButtonTooltipText?: string;
  breadCrumbs: BreadCrumb[];
  children?: React.ReactNode;
  onBackClick: () => void;
}

export const SecondaryPageHeader = ({
  pageTitle,
  children,
  breadCrumbs,
  backButtonTooltipText,
  onBackClick,
}: SecondaryPageHeaderProps) => {
  return (
    <DidomiSecondaryHeader className="block mb-m pb-m border-b border-solid border-b-neutral-gray-3">
      <div slot="header-title">
        <BreadCrumbs items={breadCrumbs} />
        <h1 className="w-full text-h3 font-bold font-serif text-primary-blue-6" data-skeleton>
          {pageTitle ?? EMPTY_FIELD}
        </h1>
      </div>
      <div slot="back-button">
        <DidomiBackButtonLink
          text={backButtonTooltipText}
          class="cursor-pointer"
          onClick={onBackClick}
          data-skeleton
        />
      </div>
      <div slot="actions" className="flex flex-row gap-x-xs">
        {children}
      </div>
    </DidomiSecondaryHeader>
  );
};
