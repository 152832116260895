import React from 'react';
import { DidomiButton } from '@didomi/ui-atoms-react';

interface ServiceEditActionsProps {
  onCancel: VoidFunction;
}

export const ServiceEditActions = ({ onCancel }: ServiceEditActionsProps) => {
  return (
    <div className="flex flex-row items-center gap-x-xxs">
      <DidomiButton variant="secondary" size="small" onClick={onCancel}>
        Cancel
      </DidomiButton>
      <DidomiButton type="submit" variant="main" size="small">
        Save
      </DidomiButton>
    </div>
  );
};
