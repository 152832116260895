import React from 'react';
import { AnalyticsDashboard } from '@didomi/utility-react';

import { DeliveryCloud } from '@types';

interface DeliveryCloudDetailsAnalyticsProps {
  deliveryCloud: DeliveryCloud;
}

export const DeliveryCloudDetailsAnalytics = ({
  deliveryCloud,
}: DeliveryCloudDetailsAnalyticsProps) => {
  return (
    <div>
      <AnalyticsDashboard
        url="https://app.sigmacomputing.com/embed/5-76O8JmYxrj0giWLvyir9Z0"
        controls={{ 'Delivery-Cloud-Id': deliveryCloud.id }}
        className="-m-4"
      />
    </div>
  );
};
