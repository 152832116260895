import React from 'react';

import { DidomiChip } from '@didomi/ui-atoms-react';

interface VideoPreviewProps {
  preview: string;
  alt: string;
  onPreviewClick: VoidFunction;
}

export const VideoPreview = ({ preview, alt, onPreviewClick }: VideoPreviewProps) => {
  return (
    <div className="relative w-full h-full rounded-lg overflow-hidden">
      <button className="block w-full h- h-full" onClick={onPreviewClick}>
        <div className="absolute w-full h-full flex flex-col justify-center items-center m-auto gap-y-3 z-10 bg-neutral-gray-9 bg-opacity-80">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_97_14094)">
              <circle
                cx="18.0039"
                cy="18"
                r="17.25"
                fill="white"
                stroke="#C9DFE8"
                strokeWidth="0.5"
              />
              <path
                d="M13.5039 24.4739L13.5039 11.4128C13.5039 10.609 14.4956 10.1556 15.1788 10.6471L24.2563 17.1776C24.7948 17.565 24.7948 18.3217 24.2563 18.709L15.1788 25.2396C14.4956 25.7311 13.5039 25.2777 13.5039 24.4739Z"
                fill="#1D4153"
              />
            </g>
            <defs>
              <clipPath id="clip0_97_14094">
                <rect width="35" height="35" fill="white" transform="translate(0.503906 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <DidomiChip label="Tutorial" variation="basic-outline" basicType="neutral" />
        </div>

        <div className="w-full h-40">
          <img src={preview} alt={alt} className="w-full h-full object-cover" />
        </div>
      </button>
    </div>
  );
};
