import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { DeliveryCloud } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useEditDeliveryCloud = (
  deliveryCloudId: string,
  options?: UseMutationOptions<AxiosResponse<DeliveryCloud>, AxiosError, Partial<DeliveryCloud>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (patchedDeliveryCloud: Partial<DeliveryCloud>) => {
      return axiosWithInterceptors.patch(
        `${CONFIG.environmentConfig.apiBaseUrl}v1/server-side/delivery-clouds/${deliveryCloudId}`,
        patchedDeliveryCloud,
        {
          params: {
            organization_id: organizationId,
          },
        },
      );
    },
    {
      ...options,
      onSuccess: async (response: AxiosResponse<DeliveryCloud>, variables, context) => {
        const hasObservers =
          queryClient
            .getQueryCache()
            .find(QUERY_KEYS.DELIVERY_CLOUD(deliveryCloudId))
            ?.getObserversCount() > 0;

        if (hasObservers) {
          await queryClient.invalidateQueries(QUERY_KEYS.DELIVERY_CLOUD(deliveryCloudId));
        } else {
          await queryClient.refetchQueries(QUERY_KEYS.DELIVERY_CLOUD(deliveryCloudId));
        }

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    },
  );
};
