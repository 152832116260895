import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosResponse, AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { Domain } from '@types';
import { useAxiosWithLogOut } from '../useAxiosWithInterceptors.hook';

export const useDeleteDomain = (
  options?: UseMutationOptions<AxiosResponse<Domain>, AxiosError, string>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const queryClient = useQueryClient();

  return useMutation(
    async (domainId: string) => {
      return axiosWithInterceptors.patch(
        `${CONFIG.environmentConfig.apiBaseUrl}v1/domains/${domainId}`,
        {
          status: 'to_be_deleted',
        },
        {
          params: {
            organization_id: organizationId,
          },
        },
      );
    },
    {
      ...options,
      onSuccess: async (response: AxiosResponse<Domain>, variables, context) => {
        const hasObservers =
          queryClient
            .getQueryCache()
            .find(QUERY_KEYS.DOMAINS(organizationId))
            ?.getObserversCount() > 0;

        if (hasObservers) {
          await queryClient.invalidateQueries(QUERY_KEYS.DOMAINS(organizationId));
        } else {
          await queryClient.refetchQueries(QUERY_KEYS.DOMAINS(organizationId));
        }

        options?.onSuccess?.(response, variables, context);
      },
    },
  );
};
