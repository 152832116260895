export const DYNAMIC_PARAMS = {
  DELIVERY_CLOUD_ID: 'deliveryCloudId',
  SERVICE_ID: 'serviceId',
  SERVICE_TYPE: 'serviceType',
};

export const ROUTES = {
  // First creation route
  DELIVERY_CLOUDS_START: '/delivery-clouds/services/start',
  // First creation route with service type
  DELIVERY_CLOUDS_START_TYPE: `/delivery-clouds/services/start/:${DYNAMIC_PARAMS.SERVICE_TYPE}`,

  // Create a new delivery cloud
  DELIVERY_CLOUDS_CREATE: '/delivery-clouds/create',
  // Create a new delivery cloud with service type
  DELIVERY_CLOUDS_CREATE_TYPE: `/delivery-clouds/create/:${DYNAMIC_PARAMS.SERVICE_TYPE}`,

  DELIVERY_CLOUDS: '/delivery-clouds/services',
  DELIVERY_CLOUDS_DETAILS: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,

  // Add a new service to a delivery cloud
  SERVICE_ADD: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/create`,
  // Add a new service based on a service type to an existing delivery cloud
  SERVICE_ADD_TYPE: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_TYPE}/create`,

  SERVICE_DETAILS: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_ID}`,
  SERVICE_EDIT: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/services/:${DYNAMIC_PARAMS.SERVICE_ID}/edit`,

  DOMAINS_START: `/delivery-clouds/:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}/domains/create`,
};
