import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import {
  DidomiButton,
  DidomiCardContainer,
  DidomiFiltersBar,
  DidomiHintbox,
  DidomiSkeleton,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { tw, tx } from '@twind/core';

import { DomainOverview } from '@components';
import { useAccessPolicyContext, useQuotasContext } from '@context';
import { useDeleteDomain, useDomains, useEditDeliveryCloud } from '@hooks';
import { DeleteDomainModal } from '@modals';
import { DomainStatus } from '@types';
import { domainsByDeliveryCloud, DYNAMIC_PARAMS, ROUTES } from '@utils';

interface DeliveryCloudDetailsDomainsProps {
  deliveryCloudId: string;
  baseDomain: string;
  customDomains?: string[];
}

export const DeliveryCloudDetailsDomains = ({
  deliveryCloudId,
  baseDomain,
  customDomains = [],
}: DeliveryCloudDetailsDomainsProps) => {
  const { canEdit, isAdmin } = useAccessPolicyContext();
  const { getQuotaValueByKey } = useQuotasContext();

  const navigate = useNavigate();
  const [domainIdSelected, setDomainIdSelected] = useState<string | null>(null);

  const { data: domainsData, isLoading: isLoadingDomains } = useDomains();

  const { mutateAsync: editDeliveryCloud } = useEditDeliveryCloud(deliveryCloudId);
  const { mutateAsync: deleteDomain } = useDeleteDomain();

  // TODO: Move this filter to the useDomains hook
  const filteredDomains = useMemo(() => {
    const domains = domainsData?.data ?? [];
    return domainsByDeliveryCloud(domains, customDomains);
  }, [domainsData, customDomains]);

  const hasDomains = filteredDomains.length !== 0;

  const handleAddDomain = () =>
    navigate(ROUTES.DOMAINS_START.replace(`:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`, deliveryCloudId));

  const handleDeleteDomain = async () => {
    try {
      await deleteDomain(domainIdSelected);
      await editDeliveryCloud({
        custom_domains: customDomains.filter(domain => domain !== domainIdSelected),
      });
      setDomainIdSelected(null);
    } catch (error) {
      console.error('[DEBUG] ~ handleDeleteDomain ~ error:', error);
    }
  };
  const domainsPerDeliveryCloud = getQuotaValueByKey('domains_per_delivery_cloud');
  const hasExceededQuota = domainsPerDeliveryCloud <= filteredDomains.length;

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoadingDomains} className="w-full block">
      <DidomiCardContainer
        style={{
          boxShadow: tw.theme('boxShadow.sidenav-active-section'),
          '--card-outer-padding': '0',
          '--card-inner-padding': `${tw.theme('space.xs')} ${tw.theme('space.s')}`,
          '--card-outer-bg': tw.theme('colors.neutral.gray.1'),
          '--card-inner-bg': 'none',
          '--card-border': 'none',
        }}
      >
        <div>
          <div className="flex flex-row justify-between items-center w-full mb-xs" data-skeleton>
            <p className="font-semibold text-body-normal text-secondary-cobalt-blue-4">
              Custom domains ({filteredDomains?.length})
            </p>

            <div className="flex flex-row items-center gap-x-xs">
              {hasDomains && (
                <DidomiFiltersBar
                  className={tx({
                    'block border-r border-neutral-gray-4 pr-xs': canEdit,
                  })}
                  placeholderTextFilter="Search a domain by name..."
                  searchValue=""
                  showSearch
                  collapsibleFilters={false}
                  onSearchTextChange={() => {}}
                />
              )}
              {isAdmin && (
                <DidomiTooltip
                  variant="helper"
                  content={
                    hasExceededQuota
                      ? `Only ${domainsPerDeliveryCloud} domains can be created.`
                      : ''
                  }
                >
                  <div>
                    <DidomiButton
                      variant="main"
                      size="small"
                      iconRight="new-create"
                      disabled={hasExceededQuota}
                      onClick={handleAddDomain}
                    >
                      Add domain
                    </DidomiButton>
                  </div>
                </DidomiTooltip>
              )}
            </div>
          </div>

          <div className="space-y-xxs">
            {isLoadingDomains ? (
              <>
                <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
                <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
                <DidomiSkeleton isLoading variant="unit" className="w-full h-12" />
              </>
            ) : hasDomains ? (
              filteredDomains.map(domain => (
                <DomainOverview
                  key={domain.id}
                  domain={domain}
                  disabled={domain.status !== DomainStatus.READY}
                  showDelete={canEdit}
                  onDelete={() => setDomainIdSelected(domain.id)}
                />
              ))
            ) : (
              <DidomiHintbox
                iconName="warning"
                titleText={`Delivery Cloud is currently running on: ${baseDomain}`}
                contentAlign="center"
                variant="info"
              >
                Add a custom domain to your service to ensure all requests are routed through your
                own first-party domain. This will help ensure data collection is not blocked by
                tracking prevention or adblockers, and cookies have a longer lifetime
              </DidomiHintbox>
            )}
          </div>
        </div>
      </DidomiCardContainer>

      <DeleteDomainModal
        isOpen={!!domainIdSelected}
        domainName={domainIdSelected}
        onDelete={handleDeleteDomain}
        onCancel={() => setDomainIdSelected(null)}
      />
    </DidomiSkeleton>
  );
};
