import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { DidomiBackButtonLink, DidomiLoader, DidomiSecondaryHeader } from '@didomi/ui-atoms-react';

import { ErrorState, SidebarInfoSteps } from '@components';
import {
  useCreateDeliveryCloud,
  useCreateService,
  useCustomSnackbar,
  useDeliveryCloudsDefaultData,
} from '@hooks';
import { ProcessingSteps } from '@modals';
import { DYNAMIC_PARAMS, ROUTES } from '@utils';

import {
  type CreateServiceSchemaType,
  DeliveryCloudStartByTypeForm,
} from './components/DeliveryCloudStartByTypeForm';

export const DeliveryCloudStartByType = () => {
  const { serviceType } = useParams();
  const navigate = useNavigate();

  const { deliveryCloudDefaultData, isLoading } = useDeliveryCloudsDefaultData(serviceType);

  const { mutateAsync: createDeliveryCloud } = useCreateDeliveryCloud();
  const { mutateAsync: createService } = useCreateService();

  const { displayApiErrorSnackbar, displaySnackbar } = useCustomSnackbar();

  const [isCreatingService, setIsCreatingService] = useState<boolean>(false);
  const [urlToNavigate, setURLToNavigate] = useState<string | undefined>();
  const [serviceName, setServiceName] = useState<string>('');

  if (!deliveryCloudDefaultData || !deliveryCloudDefaultData.is_available) {
    return <ErrorState />;
  }

  if (isLoading) {
    return <DidomiLoader />;
  }

  const handleDeliveryCloudSubmit = async (formData: CreateServiceSchemaType) => {
    setIsCreatingService(true);

    try {
      const { data: deliveryCloudResponse } = await createDeliveryCloud({
        name: 'My delivery cloud',
        regions: deliveryCloudDefaultData.regions,
      });

      const service = await createService({
        name: formData.name,
        type: deliveryCloudDefaultData.type,
        path: deliveryCloudDefaultData.path,
        delivery_cloud_id: deliveryCloudResponse.id,
        parameters: {
          sgtm_container_config: formData.containerConfig,
        },
      });

      setServiceName(service.data.name);
      setURLToNavigate(
        ROUTES.SERVICE_DETAILS.replace(
          `:${DYNAMIC_PARAMS.DELIVERY_CLOUD_ID}`,
          deliveryCloudResponse.id,
        ).replace(`:${DYNAMIC_PARAMS.SERVICE_ID}`, service.data.id),
      );
    } catch (error) {
      displayApiErrorSnackbar(error);
    }
  };

  const handleCloseProcessingSteps = () => {
    if (urlToNavigate) {
      setIsCreatingService(false);

      displaySnackbar(`${serviceName} successfully created`, { icon: 'check' });
      navigate(urlToNavigate, { state: { isDeliveryCloudCreated: true } });
    }
  };

  return (
    <>
      <div className="pb-m border-b border-solid border-neutral-gray-3">
        <DidomiSecondaryHeader titleText={deliveryCloudDefaultData.name}>
          <div slot="description">{deliveryCloudDefaultData.description}</div>

          <div slot="back-button">
            <DidomiBackButtonLink
              class="cursor-pointer"
              text="Back to server-side hosting"
              onClick={() => navigate(ROUTES.DELIVERY_CLOUDS_START)}
            />
          </div>

          <div slot="actions">
            <img
              className="mx-auto h-[52px] w-auto"
              src={deliveryCloudDefaultData.big_logo_url}
              alt={deliveryCloudDefaultData.name}
            />
          </div>
        </DidomiSecondaryHeader>
      </div>

      <div className="mt-l flex flex-row gap-x-l">
        <div className="max-w-[450px]">
          <SidebarInfoSteps {...deliveryCloudDefaultData.createService} withHeaderImage />
        </div>

        <DeliveryCloudStartByTypeForm
          deliveryCloud={deliveryCloudDefaultData}
          onSubmit={handleDeliveryCloudSubmit}
        />
      </div>

      <ProcessingSteps
        isOpen={isCreatingService}
        title="Delivery cloud deployment progress..."
        description="(The changes can take up to 5 min to be applied)"
        steps={[
          'Building assets',
          'Deployment of your service(s)',
          'Deployment of your Delivery Cloud(s)',
          'Ready!',
        ]}
        onClose={handleCloseProcessingSteps}
      />
    </>
  );
};
