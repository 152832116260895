import React, { type PropsWithChildren } from 'react';
import { FormProvider, type UseFormReturn } from 'react-hook-form';

interface FormProps<T> extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  methods: UseFormReturn<any, Record<string, unknown>>;
  onSubmit?: (data: T) => void;
}

export function Form<T>({
  methods,
  onSubmit = () => null,
  children,
  ...props
}: PropsWithChildren<FormProps<T>>) {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
