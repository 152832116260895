import React from 'react';

interface SectionTitleProps {
  name: string;
  description?: string;
}

export const SectionTitle = ({ name, description }: SectionTitleProps) => {
  return (
    <>
      <h3 className="text-body-big text-secondary-cobalt-blue-4 font-semibold">{name}</h3>

      {description && <p className="italic text-sm text-primary-blue-6">{description}</p>}
    </>
  );
};
